<template>
  <div>
    <div class="dropdown inline-block" data-placement="bottom-start">
      <button class="dropdown-toggle btn btn-primary" aria-expanded="false">
        {{ seleccionDropdown }}
        <ChevronDownIcon class="w-4 h-4 ml-2" />
      </button>
      <div class="dropdown-menu">
        <div class="dropdown-menu__content box p-5">
          <div @click="seleccionDropdown = 'Administración'">
            <div
              class="text-xs btn btn-primary"
            >
              Administración
            </div>
          </div>
          <div @click="seleccionDropdown = 'Aspectos Generales'" class="mt-3 btn btn-primary">
            <div class="text-xs">
              Aspectos Generales
            </div>
          </div>
          <div @click="seleccionDropdown = 'Caracteristicas Particulares'" class="mt-3 btn btn-primary">
            <div class="text-xs">
              Caracteristicas Particulares
            </div>
          </div>
          <div @click="seleccionDropdown = 'Colindancias'" class="mt-3 btn btn-primary">
            <div class="text-xs">
              Colindancias
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Wizard Layout -->
    <WizardAdministrativo v-if="seleccionDropdown == 'Administración'" />
    <WizardAspectosGenerales v-if="seleccionDropdown == 'Aspectos Generales'" />
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import WizardAdministrativo from '../WizardAdministracion/Main.vue'
import WizardAspectosGenerales from '../WizardAspectosGenerales/Main.vue'
export default defineComponent({
  components: {
    WizardAdministrativo,
    WizardAspectosGenerales
  },
  setup() {
    const seleccionDropdown = ref('Administración')

    return {
      seleccionDropdown
    }
  }
})
</script>

<style scoped>
</style>
