<template>
  <div>
    <div
      class="
        px-5
        sm:px-20
        mt-10
        pt-10
        border-t border-gray-200
        dark:border-dark-5
      "
    >
      <div class="font-medium text-base">Costo del Avalúo</div>
      <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-costoAvaluo" class="form-label"
            >Costo del Avalúo</label
          >
          <input
            id="input-wizard-costoAvaluo"
            type="text"
            class="form-control"
            placeholder="Costo del Avalúo"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-tipoArancel" class="form-label"
            >Tipo de Arancel</label
          >
          <input
            id="input-wizard-tipoArancel"
            type="text"
            class="form-control"
            placeholder="example@gmail.com"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-3" class="form-label">Tipo de Arancel</label>
          <input
            id="input-wizard-3"
            type="text"
            class="form-control"
            placeholder="Important Meeting"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-porcentajeBaseValuador" class="form-label"
            >Porcentaje Base Valuador</label
          >
          <input
            id="input-wizard-porcentajeBaseValuador"
            type="text"
            class="form-control"
            placeholder="Porcentaje Base Valuador"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label
            for="input-wizard-porcentajeAjustableValuador"
            class="form-label"
            >Porcentaje Ajustable Valuador</label
          >
          <input
            id="input-wizard-porcentajeAjustableValuador"
            type="text"
            class="form-control"
            placeholder="Porcentaje Ajustable Valuador"
          />
        </div>
        <div
          class="
            intro-y
            col-span-12
            flex
            items-center
            justify-center
            sm:justify-end
            mt-5
          "
        >
          <button @click="SaveCostoAvaluo" class="btn btn-primary w-36 ml-2">Guardar y Continuar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup(props, { emit }) {
    const SaveCostoAvaluo = () => {
      emit('CambioPanel', 'Firmar')
    }
    return {
      SaveCostoAvaluo
    }
  }
})
</script>

<style scoped>
</style>
