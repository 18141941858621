<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Clientes</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#superlarge-slide-over-size-preview"
          class="btn btn-primary mr-1 mb-2"
          @click="limpiarSlider"
          >Nuevo Cliente</a
        >
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              v-model="filter.field"
              class="
                form-select
                w-full
                sm:w-32
                2xl:w-full
                mt-2
                sm:mt-0 sm:w-auto
              "
            >
              <option value="name">Name</option>
              <option value="category">Category</option>
              <option value="remaining_stock">Remaining Stock</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Type</label
            >
            <select
              id="tabulator-html-filter-type"
              v-model="filter.type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like" selected>like</option>
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              v-model="filter.value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Super Large Slide Over Content -->
    <div
      id="superlarge-slide-over-size-preview"
      class="modal modal-slide-over"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <h2 class="font-medium text-base mr-auto">Nuevo Registro</h2>
          </div>
          <div class="modal-body">
            <form class="container">
              <div class="font-medium text-lg p-2">
                Cliente
                <div class="grid grid-cols-12 gap-2 p-2">
                  <input
                    v-model="Nombre"
                    type="text"
                    class="form-control col-span-12"
                    placeholder="Nombre"
                    aria-label="Nombre Cliente"
                  />
                </div>
              </div>
              <div class="font-medium text-lg p-2">
                Domicilio:
                <div class="grid grid-cols-12 gap-2 p-2">
                  <input
                    v-model="Calle"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Calle"
                    aria-label="Calle"
                  />
                  <input
                    v-model="NumExt"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Numero Ext."
                    aria-label="Numero Ext."
                  />
                  <input
                    v-model="NumInt"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Numero Int."
                    aria-label="Numero Int."
                  />
                  <input
                    v-model="Colonia"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Colonia"
                    aria-label="Colonia"
                  />
                  <input
                    v-model="Municipio"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Municipio"
                    aria-label="Municipio"
                  />
                  <input
                    v-model="Estado"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Estado"
                    aria-label="Estado"
                  />
                  <input
                    v-model="CP"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Código Postal"
                    aria-label="Código Postal"
                  />
                </div>
              </div>
              <div class="font-medium text-lg p-2">
                Contactos
                <div class="grid grid-cols-12 gap-2 p-2">
                  <button
                    @click.prevent="newContacto"
                    class="col-span-12 justify-self-end btn"
                  >
                    New
                    <PlusIcon />
                  </button>
                </div>
                <div
                  v-for="(Contacto, k) in Contactos"
                  :key="k"
                  class="grid grid-cols-12 gap-2 p-2"
                >
                  <div
                    class="form-control col-span-1 text-center"
                    @click="deleteContact(k, Contacto)"
                  >
                    <Trash2Icon />
                  </div>
                  <input
                    v-model="Contacto.Nombre"
                    type="text"
                    class="form-control col-span-6"
                    placeholder="Nombre Contacto"
                    aria-label="Nombre Contacto"
                  />
                  <input
                    v-model="Contacto.Iniciales"
                    type="text"
                    class="form-control col-span-2"
                    placeholder="Iniciales"
                    aria-label="Iniciales Contacto"
                  />
                  <input
                    v-model="Contacto.Telefono"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Telefono"
                    aria-label="Telefono Contacto"
                  />
                </div>
              </div>
              <input
                v-if="nuevo"
                data-dismiss="modal"
                class="w-full h-10 px-5 bg-success"
                type="submit"
                value="Enviar"
                @click="crearCliente"
              />
              <input
                v-else
                data-dismiss="modal"
                class="w-full h-10 px-5 bg-success"
                type="submit"
                value="Editar"
                @click="editarCliente"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Super Large Slide Over Content -->
    <Toast />
    <!-- Begin: Modal Delete -->
    <div
      id="delete-modal-preview"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Estas Seguro?</div>
              <div class="text-gray-600 mt-2">
                Desea eliminar el id {{ id }} Cliente {{ Nombre }} ?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="
                  btn btn-outline-secondary
                  w-24
                  dark:border-dark-5 dark:text-gray-300
                  mr-1
                "
              >
                Cancel
              </button>
              <button
                data-dismiss="modal"
                type="button"
                class="btn btn-danger w-24"
                @click="eliminarCliente"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, toRefs } from 'vue'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import todoClientes from '../../apiaccess/todoClientes'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
export default defineComponent({
  setup() {
    const toast = useToast()
    /* variables para llamada api */
    const nuevo = ref(true)
    /* variables para token y store */
    const store = useStore()
    const token = ref(store.getters['firebase/GetToken'])
    /* variables para tabla */
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'name',
      type: 'like',
      value: ''
    })
    /* variables para el slider */
    const slider = reactive({
      id: null,
      Nombre: null,
      Calle: null,
      NumExt: null,
      NumInt: null,
      Colonia: null,
      Municipio: null,
      Estado: null,
      CP: null
    })
    const Contactos = ref([
      {
        Nombre: null,
        Telefono: null,
        Iniciales: null
      }
    ])
    /* Funciones para la tabla */
    const getClientes = (url, config, params) => {
      return new Promise(function (resolve, reject) {
        todoClientes
          .get(token.value, params)
          .then((data) => {
            resolve(data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: 'http://127.0.0.1:8000/administrativo/clientes/',
        ajaxRequestFunc: getClientes,
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },
          // For HTML table
          {
            title: 'id',
            minWidth: 100,
            responsive: 1,
            field: 'id',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true
          },
          {
            title: 'Nombre',
            minWidth: 100,
            responsive: 1,
            field: 'Nombre',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true,
            headerSort: false
          },
          {
            title: 'ACTIONS',
            minWidth: 246,
            field: 'actions',
            responsive: 0,
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort: false,
            print: false,
            download: false,
            columns: [
              {
                title: 'Editar',
                width: 80,
                field: 'Editar',
                hozAlign: 'center',
                headerSort: false,
                responsive: 0,
                print: false,
                download: false,
                formatter() {
                  return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#superlarge-slide-over-size-preview" class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i>
                </a>
                </div>`
                },
                cellClick: function (e, cell) {
                  initTabulator()
                  nuevo.value = false
                  slider.id = cell.getData().id
                  slider.Nombre = cell.getData().Nombre
                  slider.Calle = cell.getData().Calle
                  slider.NumExt = cell.getData().NumExt
                  slider.NumInt = cell.getData().NumInt
                  slider.Colonia = cell.getData().Colonia
                  slider.Municipio = cell.getData().Municipio
                  slider.Estado = cell.getData().Estado
                  slider.CP = cell.getData().CP
                  Contactos.value = cell.getData().Contactos
                }
              },
              {
                title: 'Eliminar',
                width: 100,
                hozAlign: 'center',
                field: 'Eliminar',
                headerSort: false,
                responsive: 0,
                print: false,
                download: false,
                formatter() {
                  return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#delete-modal-preview" class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="trash-2" class="w-4 h-4 mr-1"></i>
                </a>
                </div>`
                },
                cellClick: function (e, cell) {
                  slider.id = cell.getData().id
                  slider.Nombre = cell.getData().Nombre
                }
              }
            ]
          }
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }

    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }
    /* Crea el valor y ejecuta la funcion subirArchivos */
    const crearCliente = async (e) => {
      e.preventDefault()
      if (slider.CP == null || slider.CP == '') {
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'Agregar Codigo Postal',
          life: 3000
        })
      } else {
        const obj = {
          Nombre: slider.Nombre,
          Calle: slider.Calle,
          NumExt: slider.NumExt,
          NumInt: slider.NumInt,
          Colonia: slider.Colonia,
          Municipio: slider.Municipio,
          Estado: slider.Estado,
          CP: slider.CP,
          Contactos: Contactos.value
        }
        try {
          await todoClientes.post(token.value, obj)
          initTabulator()
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Se creo Correctamente el Valor',
            life: 3000
          })
        } catch (error) {
          toast.add({
            severity: 'error',
            summary: 'error Message',
            detail: error.response,
            life: 3000
          })
          console.error(error.response.data)
        }
      }
    }
    const editarCliente = async (e) => {
      e.preventDefault()
      if (slider.CP == null || slider.CP == '') {
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'Agregar Codigo Postal',
          life: 3000
        })
      } else {
        const obj = {
          Nombre: slider.Nombre,
          Calle: slider.Calle,
          NumExt: slider.NumExt,
          NumInt: slider.NumInt,
          Colonia: slider.Colonia,
          Municipio: slider.Municipio,
          Estado: slider.Estado,
          CP: slider.CP,
          Contactos: Contactos.value
        }
        try {
          await todoClientes.patch(token.value, obj, slider.id)
          initTabulator()
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Se Edito Correctamente',
            life: 3000
          })
        } catch (error) {
          toast.add({
            severity: 'error',
            summary: 'error Message',
            detail: error.response,
            life: 3000
          })
          console.error(error.response.data)
        }
      }
    }
    /* Elimina Valor */
    const eliminarCliente = async () => {
      try {
        await todoClientes.delete(token.value, slider.id)
        toast.add({
          severity: 'success',
          summary: 'success Message',
          detail: 'Se elimino correctamente el valor',
          life: 3000
        })
        initTabulator()
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response.data,
          life: 3000
        })
      }
    }
    /* Limpia los campos al abrir nuevo valor */
    const limpiarSlider = () => {
      nuevo.value = true
      slider.id = null
      slider.Nombre = null
      slider.Calle = null
      slider.NumExt = null
      slider.NumInt = null
      slider.Colonia = null
      slider.Municipio = null
      slider.Estado = null
      slider.CP = null
      Contactos.value = [
        {
          Nombre: null,
          Telefono: null,
          Iniciales: null
        }
      ]
    }
    const newContacto = () => {
      Contactos.value.push({
        Nombre: null,
        Telefono: null,
        Iniciales: null
      })
    }
    const deleteContact = (index, Contacto) => {
      const idx = Contactos.value.indexOf(Contacto)
      if (idx > -1) {
        Contactos.value.splice(idx, 1)
      }
    }
    onMounted(() => {
      initTabulator()
      reInitOnResizeWindow()
    })
    return {
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onPrint,
      ...toRefs(slider),
      crearCliente,
      limpiarSlider,
      eliminarCliente,
      editarCliente,
      nuevo,
      Contactos,
      newContacto,
      deleteContact
    }
  }
})
</script>
