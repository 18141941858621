<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Valor</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#superlarge-slide-over-size-preview"
          class="btn btn-primary mr-1 mb-2"
          @click="limpiarSlider"
          >Nuevo Valor</a
        >
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <tabla ref="tableComponent" :column="column" :apiCall="apiCall"> </tabla>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Super Large Slide Over Content -->
    <div
      id="superlarge-slide-over-size-preview"
      class="modal modal-slide-over"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <h2 class="font-medium text-base mr-auto">Nuevo Registro</h2>
          </div>
          <div class="modal-body">
            <form class="container">
              <div class="grid grid-cols-12 p-2 gap-2">
                <label class="col-span-4">
                  Clientes
                  <AutoComplete
                    v-model="Cliente"
                    :suggestions="filteredCliente"
                    :dropdown="true"
                    @complete="searchCliente($event)"
                    @item-select="SeleccionCliente($event)"
                    field="Nombre"
                    style="width: 100%"
                    inputStyle="width: 100%"
                    placeholder="Cliente"
                  />
                </label>
                <label for="" class="col-span-4">
                  Contacto Nombre
                  <AutoComplete
                    v-model="Contacto"
                    :suggestions="filteredContacto"
                    :dropdown="true"
                    field="Nombre"
                    @complete="searchContacto($event)"
                    @item-select="SeleccionContacto($event)"
                    style="width: 100%"
                    inputStyle="width: 100%"
                    placeholder="Contacto"
                  />
                </label>
                <label for="" class="col-span-4">
                  Contacto Telefono
                  <input
                    v-model="ContactoTelefono"
                    type="text"
                    class="form-control col-span-4"
                    placeholder="Telefono"
                    aria-label="Telefono Contacto"
                  />
                </label>
                <label for="" class="col-span-4">
                  Contacto Iniciales
                  <input
                    v-model="ContactoIniciales"
                    type="text"
                    class="form-control col-span-4"
                    placeholder="Iniciales"
                    aria-label="Iniciales"
                  />
                </label>
                <div class="col-span-12 font-medium text-lg p-2">Inmuebles</div>
                <label for="" class="col-span-4">
                  Inmueble Clave
                  <AutoComplete
                    v-model="InmuebleClave"
                    :suggestions="filteredInmueble"
                    :dropdown="true"
                    field="Clave"
                    @complete="searchInmueble($event)"
                    @item-select="SeleccionInmueble($event)"
                    style="width: 100%"
                    inputStyle="width: 100%"
                    placeholder="Clave catastral"
                  />
                </label>
                <label for="" class="col-span-4">
                  Inmueble Calle
                  <input
                    v-model="InmuebleCalle"
                    type="text"
                    class="form-control col-span-4"
                    placeholder="Calle"
                    aria-label="Calle Inmueble"
                  />
                </label>
                <label for="" class="col-span-4">
                  Numero Exterior
                  <input
                    v-model="InmuebleNumExt"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Exterior"
                    aria-label="Número Exterior"
                  />
                </label>
                <label for="" class="col-span-4">
                  Numero Interior
                  <input
                    v-model="InmuebleNumInt"
                    type="text"
                    class="form-control col-span-2"
                    placeholder="Interior"
                    aria-label="Número Interior"
                  />
                </label>
                <label for="" class="col-span-4">
                  Colonia
                  <input
                    v-model="InmuebleColonia"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Colonia"
                    aria-label="Colonia Inmueble"
                  />
                </label>
                <label for="" class="col-span-4">
                  Municipio
                  <input
                    v-model="InmuebleMunicipio"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Municipio"
                    aria-label="Municipio Inmueble"
                  />
                </label>
                <label for="" class="col-span-4">
                  Estado
                  <input
                    v-model="InmuebleEstado"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Estado"
                    aria-label="Estado Inmueble"
                  />
                </label>
                <label for="" class="col-span-4">
                  Código Postal
                  <input
                    v-model="InmuebleCP"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="CP"
                    aria-label="CP Inmueble"
                  />
                </label>
                <label for="" class="col-span-4">
                  Expediente
                  <input
                    v-model="Expediente"
                    type="text"
                    class="form-control"
                    placeholder="Expediente"
                    aria-label="Expediente"
                  />
                </label>
                <label for="" class="col-span-4">
                  Tipo Terreno
                  <Dropdown
                    class="form-select"
                    v-model="InmuebleTipo"
                    :options="type"
                    optionLabel="name"
                    :editable="true"
                  />
                </label>
                <label for="" class="col-span-4">
                  Tipo Solicitud
                  <Dropdown
                    class="form-select"
                    v-model="TipoSolicitud"
                    :options="typeSolicitud"
                    optionLabel="name"
                    :editable="true"
                  />
                </label>
                <label for="" class="col-span-4">
                  Equipo
                  <AutoComplete
                    v-model="Equipo"
                    :suggestions="filteredEquipo"
                    :dropdown="true"
                    field="Equipo"
                    @complete="searchEquipo($event)"
                    @item-select="SeleccionEquipo($event)"
                    style="width: 100%"
                    inputStyle="width: 100%"
                    class="form-control"
                    placeholder="Equipo"
                  />
                </label>
              </div>
              <div class="font-medium text-lg p-2 col-span-3 p-fluid">
                Auxiliares
                <AutoComplete
                  v-model="Auxiliares"
                  :suggestions="filteredAuxiliares"
                  :multiple="true"
                  :dropdown="true"
                  field="Nombre"
                  @complete="searchAuxiliares($event)"
                  @keydown.enter="SeleccionarEscrito($event)"
                  style="width: 100%"
                  inputStyle="width: 100%"
                  class=""
                  placeholder="Equipo"
                />
              </div>
              <div class="grid grid-cols-12 gap-2 p-2">
                <div class="font-medium text-lg p-2 col-span-4">
                  Fecha de creación
                  <input
                    v-model="Fecha"
                    class="form-control"
                    type="date"
                    @change="changeFecha"
                  />
                </div>
                <div class="font-medium text-lg p-2 col-span-4">
                  Fecha Entrega
                  <input
                    v-model="FechaEntrega"
                    class="form-control"
                    type="date"
                  />
                </div>
                <div class="font-medium text-lg p-2 col-span-4">
                  Dias para Entregar
                  <input
                    v-model="DiasParaEntregar"
                    type="text"
                    class="form-control"
                    placeholder="Dias Entrega"
                    aria-label="Dias Entrega"
                    disabled
                  />
                </div>
              </div>
              <input
                v-if="nuevo"
                data-dismiss="modal"
                class="w-full h-10 px-5 bg-success"
                type="submit"
                value="Enviar"
                @click="crearValor"
              />
              <input
                v-else
                data-dismiss="modal"
                class="w-full h-10 px-5 bg-success"
                type="submit"
                value="Editar"
                @click="editarValor"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Super Large Slide Over Content -->
    <Toast />
    <!-- Begin: Modal Delete -->
    <div
      id="delete-modal-preview"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Estas Seguro?</div>
              <div class="text-gray-600 mt-2">
                Desea eliminar el id {{ Folio }} Cliente {{ Cliente }} ?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="
                  btn btn-outline-secondary
                  w-24
                  dark:border-dark-5 dark:text-gray-300
                  mr-1
                "
              >
                Cancel
              </button>
              <button
                data-dismiss="modal"
                type="button"
                class="btn btn-danger w-24"
                @click="eliminarValor"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Begin: Modal Solicitud -->
    <div
      id="converter-modal-preview"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <AlertOctagonIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Estas Seguro?</div>
              <div class="text-gray-600 mt-2">
                Desea convertir a solicitud el id {{ Folio }} Cliente
                {{ Cliente }} ?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="
                  btn btn-outline-secondary
                  w-24
                  dark:border-dark-5 dark:text-gray-300
                  mr-1
                "
              >
                Cancel
              </button>
              <button
                data-dismiss="modal"
                type="button"
                class="btn btn-danger w-24"
                @click="crearSolicitud"
              >
                Convertir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Begin: Modal Estatus -->
    <div
      id="estatus-modal-preview"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <AlertOctagonIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Estas Seguro?</div>
              <div class="text-gray-600 mt-2">
                A que estatus desea cambiar el id {{ Folio }} Cliente
                {{ Cliente }} ?
              </div>
              <div class="dropdown inline-block" data-placement="bottom-start">
                <button
                  class="dropdown-toggle btn btn-primary"
                  aria-expanded="false"
                >
                  {{ seleccionDropdown }}
                  <ChevronDownIcon class="w-4 h-4 ml-2" />
                </button>
                <div class="dropdown-menu">
                  <div class="dropdown-menu__content box p-5">
                    <div @click="seleccionDropdown = 'Pendiente'">
                      <div class="text-xs btn btn-primary">Pendiente</div>
                    </div>
                    <div
                      @click="seleccionDropdown = 'Concluido'"
                      class="mt-3 btn btn-primary"
                    >
                      <div class="text-xs">Concluido</div>
                    </div>
                    <div
                      @click="seleccionDropdown = 'Cancelado'"
                      class="mt-3 btn btn-primary"
                    >
                      <div class="text-xs">Cancelado</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="
                  btn btn-outline-secondary
                  w-24
                  dark:border-dark-5 dark:text-gray-300
                  mr-1
                "
              >
                Cancel
              </button>
              <button
                data-dismiss="modal"
                type="button"
                class="btn btn-danger w-24"
                @click="cambiarStatus"
              >
                Cambiar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRefs,
  computed
} from 'vue'
import todoValores from '../../apiaccess/todoValores'
import todoSolicitudes from '../../apiaccess/todoSolicitudes'
import todoClientes from '../../apiaccess/todoClientes'
import todoInmuebles from '../../apiaccess/todoInmuebles'
import todoEquipos from '../../apiaccess/todoEquipos'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import tabla from '@/global-components/tablaGeneral/Main.vue'
export default defineComponent({
  setup() {
    const toast = useToast()
    /* variables para llamada api */
    const nuevo = ref(true)
    const Clientes = ref()
    const seleccionDropdown = ref('Pendiente')
    const Inmuebles = ref()
    const Equipos = ref()
    const AuxiliaresAPI = ref()
    const ContactosAPI = ref()
    /* variables para token y store */
    const store = useStore()
    const token = ref(store.getters['firebase/GetToken'])
    /* variables para el slider */
    const slider = reactive({
      id: null,
      Folio: null,
      Anio: new Date().getFullYear(),
      Fecha: new Date(new Date().toString().split('GMT')[0] + ' UTC')
        .toISOString()
        .split('T')[0],
      Cliente: null,
      Contacto: null,
      ContactoTelefono: null,
      ContactoIniciales: null,
      InmuebleClave: null,
      InmuebleTipo: null,
      InmuebleCalle: null,
      InmuebleNumExt: null,
      InmuebleNumInt: null,
      InmuebleColonia: null,
      InmuebleMunicipio: null,
      InmuebleEstado: null,
      InmuebleCP: null,
      Expediente: null,
      TipoSolicitud: null,
      Equipo: null,
      Auxiliares: [],
      FechaEntrega: '',
      DiasParaEntregar: computed(() => {
        const fechaFin = new Date(slider.FechaEntrega)
        let fechaInicio = new Date(slider.Fecha)
        const dias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24) + 1
        let semanas = 0
        for (let index = 0; index < dias; index++) {
          if (fechaInicio.getDay() == 5 || fechaInicio.getDay() == 6) semanas++
          fechaInicio = fechaInicio.valueOf()
          fechaInicio += 1000 * 60 * 60 * 24
          fechaInicio = new Date(fechaInicio)
        }
        return dias - semanas
      }),
      Estatus: 'Pendiente',
      Solicitantes: [
        {
          Nombre: null,
          CURP: null,
          RFC: null
        }
      ]
    })
    const changeFecha = () => {
      const diaActual = new Date(slider.Fecha)
      fechaEntregaOtros(diaActual)
    }
    const filteredCliente = ref(null)
    const filteredInmueble = ref(null)
    const filteredEquipo = ref(null)
    const filteredAuxiliares = ref(null)
    const filteredContacto = ref(null)
    const type = ref([{ name: 'Terreno' }, { name: 'Casa Habitacion' }])
    const typeSolicitud = ref([
      { name: 'Avaluo' },
      { name: 'Condominio' },
      { name: 'Valor' }
    ])
    /* Funciones para el slider */
    const fechaEntregaOtros = (diaActual) => {
      let fechaE = null
      if (diaActual.getDay() === 0) {
        fechaE = new Date(
          diaActual.getFullYear(),
          diaActual.getMonth(),
          diaActual.getDate() + 5
        )
      } else if (
        diaActual.getDay() === 1 ||
        diaActual.getDay() === 2 ||
        diaActual.getDay() === 3 ||
        diaActual.getDay() === 4 ||
        diaActual.getDay() === 5
      ) {
        fechaE = new Date(
          diaActual.getFullYear(),
          diaActual.getMonth(),
          diaActual.getDate() + 7
        )
      } else if (diaActual.getDay() === 6) {
        fechaE = new Date(
          diaActual.getFullYear(),
          diaActual.getMonth(),
          diaActual.getDate() + 6
        )
      }
      slider.FechaEntrega = fechaE.toISOString().split('T')[0]
    }
    const searchCliente = (event) => {
      if (!event.query.trim().length) {
        filteredCliente.value = [...Clientes.value]
      } else {
        filteredCliente.value = Clientes.value.filter((inmueble) => {
          return inmueble.Nombre.toLowerCase().startsWith(
            event.query.toLowerCase()
          )
        })
      }
    }
    const searchInmueble = (event) => {
      if (!event.query.trim().length) {
        filteredInmueble.value = [...Inmuebles.value]
        filteredInmueble.value = Inmuebles.value.filter((inmueble) => {
          // Validar si existe la clave catastral en el inmueble
          if (inmueble.Clave != null) {
            return inmueble.Clave.toLowerCase().startsWith(
              event.query.toLowerCase()
            )
          } else {
            return ''
          }
        })
      } else {
        filteredInmueble.value = Inmuebles.value.filter((inmueble) => {
          // Validar si existe la clave catastral en el inmueble
          if (inmueble.Clave != null) {
            return inmueble.Clave.toLowerCase().startsWith(
              event.query.toLowerCase()
            )
          } else {
            return ''
          }
        })
      }
    }
    const searchEquipo = (event) => {
      if (!event.query.trim().length) {
        filteredEquipo.value = [...Equipos.value]
      } else {
        filteredEquipo.value = Equipos.value.filter((equipo) => {
          return equipo.Equipo.toLowerCase().startsWith(
            event.query.toLowerCase()
          )
        })
      }
    }
    const searchAuxiliares = (event) => {
      if (slider.Equipo === null || slider.Equipo === '') {
        toast.add({
          severity: 'info',
          summary: 'Info Message',
          detail: 'Seleccione un Equipo',
          life: 3000
        })
        filteredAuxiliares.value = []
      } else {
        if (!event.query.trim().length) {
          filteredAuxiliares.value = [...AuxiliaresAPI.value]
        } else {
          filteredAuxiliares.value = AuxiliaresAPI.value.filter((equipo) => {
            return equipo.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase())
          })
        }
      }
    }
    const searchContacto = (event) => {
      if (slider.Cliente === null || slider.Cliente === '') {
        toast.add({
          severity: 'info',
          summary: 'Info Message',
          detail: 'Seleccione un Cliente',
          life: 3000
        })
        filteredContacto.value = []
      } else {
        if (!event.query.trim().length) {
          filteredContacto.value = [...ContactosAPI.value]
        } else {
          filteredContacto.value = ContactosAPI.value.filter((equipo) => {
            return equipo.Nombre.toLowerCase().startsWith(
              event.query.toLowerCase()
            )
          })
        }
      }
    }
    const SeleccionEquipo = (event) => {
      slider.Equipo = event.value.Equipo
      AuxiliaresAPI.value = event.value.Auxiliares
    }
    const SeleccionCliente = (event) => {
      slider.Cliente = event.value.Nombre
      ContactosAPI.value = event.value.Contactos
    }
    const SeleccionContacto = (event) => {
      slider.Contacto = event.value.Nombre
      slider.ContactoTelefono = event.value.Telefono
      slider.ContactoIniciales = event.value.Iniciales
    }
    const SeleccionInmueble = (event) => {
      slider.InmuebleClave = event.value.Clave
      slider.InmuebleTipo = event.value.Tipo
      slider.InmuebleCalle = event.value.Calle
      slider.InmuebleNumExt = event.value.NumExt
      slider.InmuebleNumInt = event.value.NumInt
      slider.InmuebleCP = event.value.CP
      slider.InmuebleColonia = event.value.Colonia
      slider.InmuebleMunicipio = event.value.Municipio
      slider.InmuebleEstado = event.value.Estado
    }
    const SeleccionarEscrito = (event) => {
      event.preventDefault()
      if (slider.Equipo === null || slider.Equipo === '') {
        toast.add({
          severity: 'info',
          summary: 'Info Message',
          detail: 'Seleccione un Equipo',
          life: 3000
        })
        filteredAuxiliares.value = []
      } else {
        if (event.srcElement.value === '') {
          toast.add({
            severity: 'info',
            summary: 'Info Message',
            detail: 'Seleccione un Auxiliar Valido',
            life: 3000
          })
        } else {
          slider.Auxiliares.push({ name: event.srcElement.value })
        }
      }
    }
    /* Funciones para la tabla */
    /* variables para tabla */
    const tableComponent = ref()
    const column = [
      {
        formatter: 'responsiveCollapse',
        width: 40,
        minWidth: 30,
        hozAlign: 'center',
        resizable: false,
        headerSort: false
      },
      // For HTML table
      {
        title: 'Folio',
        minWidth: 100,
        responsive: 0,
        field: 'Folio',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Fecha',
        minWidth: 100,
        responsive: 0,
        field: 'Fecha',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true,
        headerSort: false
      },
      {
        title: 'Cliente',
        minWidth: 120,
        responsive: 0,
        field: 'Cliente',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Expediente',
        minWidth: 150,
        responsive: 0,
        field: 'Expediente',
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: true,
        download: true,
        formatter(cell) {
          return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#converter-modal-preview" class="flex items-center mr-3 btn font-12" href="javascript:;">
                  ${cell.getData().Expediente}
                </a>
                </div>`
        },
        cellClick: function (e, cell) {
          slider.Anio = cell.getData().Anio
          slider.Cliente = cell.getData().Cliente
          slider.Contacto = cell.getData().Contacto
          slider.ContactoTelefono = cell.getData().ContactoTelefono
          slider.ContactoIniciales = cell.getData().ContactoIniciales
          slider.InmuebleClave = cell.getData().InmuebleClave
          slider.InmuebleTipo = cell.getData().InmuebleTipo
          slider.InmuebleCalle = cell.getData().InmuebleCalle
          slider.InmuebleNumExt = cell.getData().InmuebleNumExt
          slider.InmuebleNumInt = cell.getData().InmuebleNumInt
          slider.InmuebleColonia = cell.getData().InmuebleColonia
          slider.InmuebleMunicipio = cell.getData().InmuebleMunicipio
          slider.InmuebleEstado = cell.getData().InmuebleEstado
          slider.InmuebleCP = cell.getData().InmuebleCP
          slider.Expediente = cell.getData().Expediente
          slider.TipoSolicitud = cell.getData().TipoSolicitud
          slider.Equipo = cell.getData().Equipo
          slider.Auxiliares = cell.getData().Auxiliares
          slider.Estatus = cell.getData().Estatus
        }
      },
      {
        title: 'Inmueble Clave',
        minWidth: 150,
        responsive: 0,
        field: 'InmuebleClave',
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: true,
        download: true,
        formatter(cell) {
          return `<div class="flex lg:justify-center items-center font-12">
                  ${cell.getData().InmuebleClave}
                </div>`
        }
      },
      {
        title: 'Calle',
        minWidth: 200,
        responsive: 0,
        field: 'InmuebleCalle',
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: true,
        download: true,
        formatter(cell) {
          return `
                <input type="text" class="font-12 text-center w-full" value="Calle: ${
                  cell.getData().InmuebleCalle
                } Num: # ${cell.getData().InmuebleNumExt} Int: # ${
            cell.getData().InmuebleNumInt
          } Col. ${cell.getData().InmuebleColonia} Estado: ${
            cell.getData().InmuebleEstado
          } Municipio: ${cell.getData().InmuebleMunicipio} C.P ${
            cell.getData().InmuebleCP
          }" disabled>`
        },
        formatterPrint: function printFormatter(
          cell,
          formatterParams,
          onRendered
        ) {
          return `<div class="flex lg:justify-center items-center font-10 width-940px">
                  Calle: ${cell.getData().InmuebleCalle} Num: # ${
            cell.getData().InmuebleNumExt
          } Int: # ${cell.getData().InmuebleNumInt} Col. ${
            cell.getData().InmuebleColonia
          } Estado: ${cell.getData().InmuebleEstado} Municipio: ${
            cell.getData().InmuebleMunicipio
          } C.P ${cell.getData().InmuebleCP}
                </div>`
        }
      },
      {
        title: 'Entrega',
        minWidth: 150,
        responsive: 0,
        field: 'FechaEntrega',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Disp',
        minWidth: 100,
        responsive: 0,
        field: 'DiasParaEntregar',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Status',
        minWidth: 150,
        responsive: 0,
        field: 'Estatus',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true,
        formatter(cell) {
          return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#estatus-modal-preview" class="font-12 flex items-center mr-3 btn" href="javascript:;">
                  ${cell.getData().Estatus}
                </a>
                </div>`
        },
        cellClick: function (e, cell) {
          slider.id = cell.getData().id
          slider.Folio = cell.getData().Folio
          slider.Cliente = cell.getData().Cliente
          slider.Estatus = cell.getData().Estatus
        }
      },
      {
        title: 'ACTIONS',
        minWidth: 246,
        field: 'actions',
        responsive: 0,
        hozAlign: 'center',
        vertAlign: 'middle',
        headerSort: false,
        print: false,
        download: false,
        columns: [
          {
            title: 'Editar',
            width: 80,
            field: 'Editar',
            hozAlign: 'center',
            headerSort: false,
            responsive: 0,
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#superlarge-slide-over-size-preview" class="flex items-center mr-3 font-12" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1 "></i>
                </a>
                </div>`
            },
            cellClick: function (e, cell) {
              nuevo.value = false
              slider.id = cell.getData().id
              slider.Folio = cell.getData().Folio
              slider.Anio = cell.getData().Anio
              slider.Cliente = cell.getData().Cliente
              slider.Contacto = cell.getData().Contacto
              slider.ContactoTelefono = cell.getData().ContactoTelefono
              slider.ContactoIniciales = cell.getData().ContactoIniciales
              slider.InmuebleClave = cell.getData().InmuebleClave
              slider.InmuebleTipo = cell.getData().InmuebleTipo
              slider.InmuebleCalle = cell.getData().InmuebleCalle
              slider.InmuebleNumExt = cell.getData().InmuebleNumExt
              slider.InmuebleNumInt = cell.getData().InmuebleNumInt
              slider.InmuebleColonia = cell.getData().InmuebleColonia
              slider.InmuebleMunicipio = cell.getData().InmuebleMunicipio
              slider.InmuebleEstado = cell.getData().InmuebleEstado
              slider.InmuebleCP = cell.getData().InmuebleCP
              slider.Expediente = cell.getData().Expediente
              slider.TipoSolicitud = cell.getData().TipoSolicitud
              slider.Equipo = cell.getData().Equipo
              slider.Auxiliares = cell.getData().Auxiliares
              slider.Estatus = cell.getData().Estatus
              slider.Fecha = cell.getData().Fecha
              slider.FechaEntrega = cell.getData().FechaEntrega
            }
          },
          {
            title: 'Eliminar',
            width: 100,
            hozAlign: 'center',
            field: 'Eliminar',
            headerSort: false,
            responsive: 0,
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#delete-modal-preview" class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="trash-2" class="w-4 h-4 mr-1"></i>
                </a>
                </div>`
            },
            cellClick: function (e, cell) {
              slider.id = cell.getData().id
              slider.Folio = cell.getData().Folio
              slider.Cliente = cell.getData().Cliente
            }
          }
        ]
      }
    ]
    const apiCall = (url, config, params) => {
      return new Promise(function (resolve, reject) {
        todoValores
          .get(token.value, params)
          .then((data) => {
            resolve(data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
    /* Crea el valor y ejecuta la funcion subirArchivos */
    const crearSolicitud = async () => {
      try {
        if (
          slider.TipoSolicitud !== null &&
          Object.prototype.hasOwnProperty.call(slider.TipoSolicitud, 'name')
        ) {
          slider.TipoSolicitud = slider.TipoSolicitud.name
        }
        if (
          slider.InmuebleTipo !== null &&
          Object.prototype.hasOwnProperty.call(slider.InmuebleTipo, 'name')
        ) {
          slider.InmuebleTipo = slider.InmuebleTipo.name
        }
        slider.Estatus = 'Pendiente'
        await todoSolicitudes.post(token.value, slider)
        tableComponent.value.tabulator.replaceData()
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Se creo Correctamente la solicitud',
          life: 3000
        })
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error,
          life: 3000
        })
        console.error(error)
      }
    }
    const crearValor = async (e) => {
      e.preventDefault()
      try {
        slider.Estatus = 'Pendiente'
        if (
          slider.TipoSolicitud !== null &&
          Object.prototype.hasOwnProperty.call(slider.TipoSolicitud, 'name')
        ) {
          slider.TipoSolicitud = slider.TipoSolicitud.name
        }
        if (
          slider.InmuebleTipo !== null &&
          Object.prototype.hasOwnProperty.call(slider.InmuebleTipo, 'name')
        ) {
          slider.InmuebleTipo = slider.InmuebleTipo.name
        }
        await todoValores.post(token.value, slider)
        tableComponent.value.tabulator.replaceData()
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Se creo Correctamente el Valor',
          life: 3000
        })
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response,
          life: 3000
        })
        console.error(error)
      }
    }
    const editarValor = async (e) => {
      e.preventDefault()
      try {
        const files = slider.files
        slider.files = []
        files.forEach((element) => {
          slider.files.push(element.name)
        })
        if (
          slider.TipoSolicitud !== null &&
          Object.prototype.hasOwnProperty.call(slider.TipoSolicitud, 'name')
        ) {
          slider.TipoSolicitud = slider.TipoSolicitud.name
        }
        if (
          slider.InmuebleTipo !== null &&
          Object.prototype.hasOwnProperty.call(slider.InmuebleTipo, 'name')
        ) {
          slider.InmuebleTipo = slider.InmuebleTipo.name
        }
        await todoValores.patch(token.value, slider, slider.id)
        tableComponent.value.tabulator.replaceData()
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Se Actualizo el Valor',
          life: 3000
        })
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response,
          life: 3000
        })
        console.error(error)
      }
    }
    const cambiarStatus = async (e) => {
      e.preventDefault()
      try {
        const today = new Date().toDateString()
        await todoValores.patch(
          token.value,
          {
            Estatus: seleccionDropdown.value,
            FechaEntrega: new Date(today).toISOString().split('T')[0]
          },
          slider.id
        )
        tableComponent.value.tabulator.replaceData()
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Se cambio el estatus correctamente',
          life: 3000
        })
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response,
          life: 3000
        })
        console.error(error)
      }
    }
    /* Elimina Valor */
    const eliminarValor = async () => {
      try {
        await todoValores.delete(token.value, slider.id)
        toast.add({
          severity: 'success',
          summary: 'success Message',
          detail: 'Se elimino correctamente el valor',
          life: 3000
        })
        tableComponent.value.tabulator.replaceData()
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response.data,
          life: 3000
        })
      }
    }
    /* Limpia los campos al abrir nuevo valor */
    const limpiarSlider = () => {
      nuevo.value = true
      slider.id = null
      slider.Folio = null
      slider.Anio = new Date().getFullYear()
      slider.Cliente = null
      slider.Contacto = null
      slider.ContactoTelefono = null
      slider.ContactoIniciales = null
      slider.InmuebleClave = null
      slider.InmuebleTipo = null
      slider.InmuebleCalle = null
      slider.InmuebleNumExt = null
      slider.InmuebleNumInt = null
      slider.InmuebleColonia = null
      slider.InmuebleMunicipio = null
      slider.InmuebleEstado = null
      slider.InmuebleCP = null
      slider.Expediente = null
      slider.Escritura = null
      slider.TipoSolicitud = null
      slider.Equipo = null
      slider.Auxiliares = []
      slider.Estatus = 'Pendiente'
      slider.Fecha = new Date(new Date().toString().split('GMT')[0] + ' UTC')
        .toISOString()
        .split('T')[0]
      slider.FechaEntrega = ''
      slider.Solicitantes = [
        {
          Nombre: null,
          CURP: null,
          RFC: null
        }
      ]
    }
    onMounted(() => {
      const infinito = {
        filters: [],
        page: 1,
        size: 10000,
        sorters: []
      }
      todoClientes.get(token.value, infinito).then((data) => {
        Clientes.value = data.data.data
        return Clientes.value
      })
      todoInmuebles.get(token.value, infinito).then((data) => {
        Inmuebles.value = data.data.data
        return Inmuebles.value
      })
      todoEquipos.get(token.value, infinito).then((data) => {
        Equipos.value = data.data.data
        return Equipos.value
      })
    })
    return {
      filteredCliente,
      searchCliente,
      filteredInmueble,
      searchInmueble,
      type,
      ...toRefs(slider),
      searchEquipo,
      filteredEquipo,
      filteredContacto,
      SeleccionEquipo,
      searchAuxiliares,
      filteredAuxiliares,
      SeleccionarEscrito,
      SeleccionInmueble,
      searchContacto,
      SeleccionCliente,
      SeleccionContacto,
      crearValor,
      typeSolicitud,
      limpiarSlider,
      eliminarValor,
      editarValor,
      nuevo,
      crearSolicitud,
      cambiarStatus,
      seleccionDropdown,
      tableComponent,
      column,
      apiCall,
      changeFecha
    }
  },
  components: {
    tabla
  }
})
</script>
