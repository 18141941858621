import Service from './Service'

const resourceuma = 'administrativo/logs/'

export default {
  nuevoLog(token, data) {
    return Service.post(resourceuma, data, {
      headers: {
        authorization: `Token ${token}`
      }
    })
  }
}
