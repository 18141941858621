import { createRouter, createWebHistory } from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'
import Login from '../views/login/Main.vue'
import ErrorPage from '../views/error-page/Main.vue'
import Solicitud from '../views/Solicitud/Main.vue'
import Otros from '../views/Otros/Main.vue'
import Valor from '../views/Valor/Main.vue'
import Entregas from '../views/Entregas/Main.vue'
import Solicitantes from '../views/Solicitantes/Main.vue'
import Inmuebles from '../views/Inmuebles/Main.vue'
import Clientes from '../views/Clientes/Main.vue'
import Equipos from '../views/Equipos/Main.vue'
import Facturas from '../views/Facturas/Main.vue'
import Uma from '../views/UMA/Main.vue'
import Levantamiento from '../viewsLevantamiento/Levantamiento/Main.vue'

const routes = [
  {
    path: '/',
    component: SideMenu,
    children: [
      {
        path: 'solicitud',
        name: 'side-menu-solicitud',
        component: Solicitud
      },
      {
        path: 'otros',
        name: 'side-menu-otros',
        component: Otros
      },
      {
        path: 'valor',
        name: 'side-menu-valor',
        component: Valor
      },
      {
        path: 'entregas',
        name: 'side-menu-entregas',
        component: Entregas
      },
      {
        path: 'solicitantes',
        name: 'side-menu-solicitantes',
        component: Solicitantes
      },
      {
        path: 'inmuebles',
        name: 'side-menu-inmuebles',
        component: Inmuebles
      },
      {
        path: 'clientes',
        name: 'side-menu-clientes',
        component: Clientes
      },
      {
        path: 'equipos',
        name: 'side-menu-equipos',
        component: Equipos
      },
      {
        path: 'uma',
        name: 'side-menu-Uma',
        component: Uma
      },
      {
        path: 'facturas',
        name: 'side-menu-facturas',
        component: Facturas
      },
      {
        path: 'Levantamiento',
        name: 'side-menu-levantamiento',
        component: Levantamiento
      }
    ],
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  next()
})
export default router
