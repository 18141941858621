const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: '',
        title: 'Avaluos / Valor',
        subMenu: [
          {
            icon: 'UserPlusIcon',
            pageName: 'side-menu-valor',
            title: 'Valor'
          },
          {
            icon: 'UserPlusIcon',
            pageName: 'side-menu-solicitud',
            title: 'Solicitudes'
          }
        ]
      },
      'devider',
      {
        icon: 'MailIcon',
        pageName: 'side-menu-entregas',
        title: 'Entregas'
      },
      'devider',
      {
        icon: 'Edit2Icon',
        pageName: '',
        title: 'Registros',
        subMenu: [
          {
            icon: 'UsersIcon',
            pageName: 'side-menu-solicitantes',
            title: 'Solicitantes'
          },
          {
            icon: 'CompassIcon',
            pageName: 'side-menu-inmuebles',
            title: 'Inmuebles'
          },
          {
            icon: 'KeyIcon',
            pageName: 'side-menu-clientes',
            title: 'Clientes'
          },
          {
            icon: 'LayersIcon',
            pageName: 'side-menu-equipos',
            title: 'Equipos'
          },
          {
            icon: 'LayersIcon',
            pageName: 'side-menu-Uma',
            title: 'UMA'
          }
        ]
      },
      'devider',
      {
        icon: 'FileTextIcon',
        pageName: 'side-menu-facturas',
        title: 'Facturas'
      },
      'devider'
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
