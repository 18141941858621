<template>
  <!-- BEGIN: Top Bar -->
  <div class="top-bar">
    <!-- BEGIN: Breadcrumb -->
    <div class="-intro-x breadcrumb mr-auto hidden sm:flex">
      <a href="">Application</a>
      <ChevronRightIcon class="breadcrumb__icon" />
      <a href="" class="breadcrumb--active">{{ title }}</a>
    </div>
    <!-- END: Breadcrumb -->
  </div>
  <!-- END: Top Bar -->
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  setup() {
    const searchDropdown = ref(false)
    const route = useRoute()
    const title = ref(route.path)
    const showSearchDropdown = () => {
      searchDropdown.value = true
    }
    const rutas = () => {
      title.value = route.path
      if (title.value === '/') {
        title.value = 'Dashboard'
      } else {
        title.value = title.value.replace('/', '')
        title.value = title.value[0].toUpperCase() + title.value.slice(1)
      }
    }
    rutas()
    watch(route, () => {
      title.value = route.path
      if (title.value === '/') {
        title.value = 'Dashboard'
      } else {
        title.value = title.value.replace('/', '')
        title.value = title.value[0].toUpperCase() + title.value.slice(1)
      }
    })
    const hideSearchDropdown = () => {
      searchDropdown.value = false
    }

    return {
      searchDropdown,
      showSearchDropdown,
      hideSearchDropdown,
      title
    }
  }
})
</script>
