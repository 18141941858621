import Service from './Service'

const resourceuma = 'administrativo/uma/'

export default {
  get(token) {
    return Service.get(resourceuma, {
      headers: {
        authorization: `Token ${token}`
      }
    })
  },
  saveUMA(token, id, data) {
    return Service.patch(resourceuma + id + '/', data, {
      headers: {
        authorization: `Token ${token}`
      }
    })
  }
}
