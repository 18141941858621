import Service from './Service'

const resource = 'administrativo/entrega/'

export default {
  get(token, params) {
    const parametros = {
      page: params.page,
      size: params.size
    }
    return Service.get(resource, {
      headers: {
        authorization: `Token ${token}`
      },
      params: parametros
    })
  },
  post(token, data) {
    return Service.post(resource, data, {
      headers: {
        Authorization: `Token ${token}`
      }
    })
  },
  patch(token, data, id) {
    return Service.patch(resource + id + '/', data, {
      headers: {
        Authorization: `Token ${token}`
      }
    })
  },
  delete(token, id) {
    return Service.delete(resource + id + '/', {
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }
}
