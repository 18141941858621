import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'

var firebaseConfig = {
  apiKey: 'AIzaSyCYuKqJDjQCKLKPWEDUawB-yGBSVShNOYQ',
  authDomain: 'gamavue.firebaseapp.com',
  projectId: 'gamavue',
  storageBucket: 'gamavue.appspot.com',
  messagingSenderId: '660930785838',
  appId: '1:660930785838:web:031dbc51b5ae4dcadc65bf'
}

firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
