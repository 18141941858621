import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import firebase from './firebase'
import { auth } from '../firebase'

const store = createStore({
  actions: {
    checkAuth({ commit }) {
      auth.onAuthStateChanged(function (user) {
        if (user) {
          commit('firebase/setUser', user)
          commit('firebase/setToken', user.displayName)
        } else {
          commit('firebase/setUser', null)
        }
      })
    }
  },
  modules: {
    main,
    sideMenu,
    firebase
  }
})

export function useStore() {
  return store
}

export default store

store.dispatch('checkAuth')
