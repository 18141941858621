<template>
  <div>
    <div
      class="
        px-5
        sm:px-20
        mt-10
        pt-10
        border-t border-gray-200
        dark:border-dark-5
      "
    >
      <div class="font-medium text-base">Identificación</div>
      <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-date" class="form-label"
            >Fecha del Avalúo</label
          >
          <input id="input-wizard-date" type="date" class="form-control" />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-FolioSHF" class="form-label"
            >Folio SHF</label
          >
          <input
            id="input-wizard-FolioSHF"
            type="text"
            class="form-control"
            placeholder="Folio SHF"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-FolioSolicitante" class="form-label"
            >Folio Solicitante</label
          >
          <input
            id="input-wizard-FolioSolicitante"
            type="text"
            class="form-control"
            placeholder="Folio Solicitante"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-NAEA" class="form-label">NAEA</label>
          <input
            id="input-wizard-NAEA"
            type="text"
            class="form-control"
            placeholder="NAEA"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-ClaveUnicaVivienda" class="form-label"
            >Clave Unica de Vivienda</label
          >
          <input
            id="input-wizard-ClaveUnicaVivienda"
            type="text"
            class="form-control"
            placeholder="Clave Unica de Vivienda"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-ClaveUnidadValuación" class="form-label"
            >Clave de la Unidad de Valuación</label
          >
          <input
            id="input-wizard-ClaveUnidadValuación"
            type="text"
            class="form-control"
            placeholder="Clave de la Unidad de Valuación"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-NúmeroRegistro" class="form-label"
            >Número de Registro</label
          >
          <input
            id="input-wizard-NúmeroRegistro"
            type="text"
            class="form-control"
            placeholder="Número de Registro"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-EntidadOtorganteCredito" class="form-label"
            >Entidad Otorgante del Credito</label
          >
          <input
            id="input-wizard-EntidadOtorganteCredito"
            type="text"
            class="form-control"
            placeholder="Entidad Otorgante del Credito"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-ControlInternoValuador" class="form-label"
            >Control interno del Valuador</label
          >
          <input
            id="input-wizard-ControlInternoValuador"
            type="text"
            class="form-control"
            placeholder="Control interno del Valuador"
          />
        </div>
        <div
          class="
            intro-y
            col-span-12
            flex
            items-center
            justify-center
            sm:justify-end
            mt-5
          "
        >
          <button
            @click="SaveIdentificacion"
            class="btn btn-primary w-36 ml-2"
          >
            Guardar y Continuar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup(props, { emit }) {
    const SaveIdentificacion = () => {
      emit('CambioPanel', 'Datos del Controlador')
    }
    return {
      SaveIdentificacion
    }
  }
})
</script>

<style scoped>
</style>
