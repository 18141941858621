<template>
  <div>
    <div
      class="
        px-5
        sm:px-20
        mt-10
        pt-10
        border-t border-gray-200
        dark:border-dark-5
      "
    >
      <div class="font-medium text-base">Usuarios Y Roles</div>
      <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
        <div class="intro-y col-span-12 sm:col-span-12">
          <label for="input-wizard-Controlador" class="form-label"
            >Controlador</label
          >
          <input
            id="input-wizard-Controlador"
            type="text"
            class="form-control"
            placeholder="Controlador"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-Valuador" class="form-label">Valuador</label>
          <input
            id="input-wizard-Valuador"
            type="text"
            class="form-control"
            placeholder="Valuador"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-Auxiliar-Técnico" class="form-label"
            >Auxiliar Técnico</label
          >
          <input
            id="input-wizard-Auxiliar-Técnico"
            type="text"
            class="form-control"
            placeholder="Auxiliar Técnico"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-Revisor" class="form-label">Revisor</label>
          <input
            id="input-wizard-Revisor"
            type="text"
            class="form-control"
            placeholder="Revisor"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-Auxiliar-Administrativo" class="form-label"
            >Auxiliar Administrativo</label
          >
          <input
            id="input-wizard-Auxiliar-Administrativo"
            type="text"
            class="form-control"
            placeholder="Auxiliar Administrativo"
          />
        </div>
        <div
          class="
            intro-y
            col-span-12
            flex
            items-center
            justify-center
            sm:justify-end
            mt-5
          "
        >
          <button @click="SaveUsuariosRoles" class="btn btn-primary w-36 ml-2">Guardar y Continuar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup(props, { emit }) {
    const SaveUsuariosRoles = () => {
      emit('CambioPanel', 'Costo del Avalúo')
    }
    return {
      SaveUsuariosRoles
    }
  }
})
</script>

<style scoped>
</style>
