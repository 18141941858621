<template>
  <div>
    <form>
      <div class="form-group mb-5">
        <label for="Diario">Diario</label>
        <input
          v-model="diario"
          type="text"
          class="form-control"
          id="Diario"
          aria-describedby="Diario"
          placeholder="Ingresar UMA diario"
          @change="calculoUMA"
        />
        <small id="DiarioHelp" class="form-text text-muted"
          >Subir UMA diario</small
        >
      </div>
      <div class="form-group mb-5">
        <label for="Mensual">Mensual</label>
        <input
          v-model="mensual"
          type="text"
          class="form-control"
          id="Mensual"
          aria-describedby="Mensual"
          placeholder="Calculo de acuerdo a UMA diario"
          disabled
        />
        <small id="MensualHelp" class="form-text text-muted"
          >Se calcula del UMA diario</small
        >
      </div>
      <div class="form-group mb-5">
        <label for="Anual">Anual</label>
        <input
          v-model="anual"
          type="text"
          class="form-control"
          id="Anual"
          aria-describedby="Anual"
          placeholder="Calculo de acuerdo a UMA mensual"
          disabled
        />
        <small id="AnualHelp" class="form-text text-muted"
          >Se calcula del UMA Mensual</small
        >
      </div>
      <button type="submit" class="btn btn-primary" @click.prevent="guardarUMA">
        Guardar
      </button>
    </form>
  </div>
  <Toast />
</template>

<script>
import { defineComponent, onMounted, reactive, ref, toRefs, watch } from 'vue'
import todoUMA from '@/apiaccess/todoUMA.js'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
export default defineComponent({
  setup() {
    const store = useStore()
    const token = ref(store.getters['firebase/GetToken'])
    const toast = useToast()
    const UMA = reactive({
      id: '',
      diario: '',
      mensual: '',
      anual: ''
    })
    const calculoUMA = () => {
      UMA.mensual = (UMA.diario * 30.4).toFixed(2)
      UMA.anual = (UMA.mensual * 12).toFixed(2)
    }
    watch(
      () => UMA.diario,
      () => {
        calculoUMA()
      },
      { immediate: true }
    )
    const guardarUMA = async () => {
      try {
        await todoUMA.saveUMA(token.value, UMA.id, {
          diario: UMA.diario,
          mensual: UMA.mensual,
          anual: UMA.anual
        })
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Se creo Guardo Correctamente la UMA',
          life: 3000
        })
        getUMA()
      } catch (error) {
        console.error(error)
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: error.response,
          life: 3000
        })
      }
    }
    const getUMA = async () => {
      const response = await todoUMA.get(token.value)
      UMA.id = response.data[0].id
      UMA.diario = response.data[0].diario
      UMA.mensual = response.data[0].mensual
      UMA.anual = response.data[0].anual
    }
    onMounted(() => {
      getUMA()
    })
    return {
      ...toRefs(UMA),
      calculoUMA,
      guardarUMA
    }
  }
})
</script>

<style scoped>
</style>
