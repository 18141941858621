import { auth } from '../firebase'
import todoEmpleados from '../apiaccess/todoEmpleados'

const state = {
  user: null,
  token: null
}
const getters = {
  GetToken: (state) => state.token
}
const mutations = {
  setUser(state, user) {
    state.user = user
  },
  setToken(state, token) {
    state.token = token
  }
}
const actions = {
  getCurrentUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth.onAuthStateChanged(
        (user) => {
          unsubscribe()
          resolve(user)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  async doLogin({ commit }, { email, password }) {
    (email += '@avaluos.com')
    await auth.signInWithEmailAndPassword(email, password)
    const token = await todoEmpleados.login({ email, password })
    auth.currentUser.updateProfile({
      displayName: token.data.access_token
    })
    commit('setUser', auth.currentUser)
    commit('setToken', token.data.access_token)
  },
  async doLogout({ commit }) {
    await auth.signOut()
    await todoEmpleados.logOut(state.token)
    commit('setUser', null)
    commit('setToken', null)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
