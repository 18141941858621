<template>
  <div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div
        class="wizard flex flex-col lg:flex-row justify-center px-5 sm:px-20"
      >
        <div
          class="intro-x lg:text-center flex items-center lg:block flex-1 z-10"
        >
          <button
            @click="StepByStep = 'Información General'"
            class="w-10 h-10 rounded-full btn"
            :class="
              StepByStep == 'Información General'
                ? 'btn-primary'
                : 'bg-gray-200'
            "
          >
            1
          </button>
          <div class="lg:w-32 font-medium text-base lg:mt-3 ml-3 lg:mx-auto">
            Información General
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Usuarios y Roles'"
            class="w-10 h-10 rounded-full btn"
            :class="
              StepByStep == 'Usuarios y Roles' ? 'btn-primary' : 'bg-gray-200'
            "
          >
            2
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Usuarios y Roles
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Costo del Avalúo'"
            class="w-10 h-10 rounded-full btn"
            :class="
              StepByStep == 'Costo del Avalúo' ? 'btn-primary' : 'bg-gray-200'
            "
          >
            3
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Costo del Avalúo
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Firmar'"
            class="w-10 h-10 rounded-full btn"
            :class="StepByStep == 'Firmar' ? 'btn-primary' : 'bg-gray-200'"
          >
            4
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Firmar
          </div>
        </div>
      </div>
      <PanelAdministracion
        v-if="StepByStep == 'Información General'"
        @CambioPanel="CambioPanel($event)"
      />
      <PanelUsuariosRoles v-if="StepByStep == 'Usuarios y Roles'" @CambioPanel="CambioPanel($event)" />
      <PanelCostoAvaluo v-if="StepByStep == 'Costo del Avalúo'" @CambioPanel="CambioPanel($event)" />
      <PanelFirmar v-if="StepByStep == 'Firmar'" @CambioPanel="CambioPanel($event)" />
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import PanelAdministracion from './Paneles/InformacionGeneral/Main.vue'
import PanelUsuariosRoles from './Paneles/UsuariosRoles/Main.vue'
import PanelCostoAvaluo from './Paneles/CostoAvaluo/Main.vue'
import PanelFirmar from './Paneles/Firmar/Main.vue'
export default defineComponent({
  components: {
    PanelAdministracion,
    PanelUsuariosRoles,
    PanelCostoAvaluo,
    PanelFirmar
  },
  setup() {
    const StepByStep = ref('Información General')
    const CambioPanel = (panel) => {
      StepByStep.value = panel
    }
    return {
      StepByStep,
      CambioPanel
    }
  }
})
</script>

<style scoped>
</style>
