import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import * as Sentry from '@sentry/vue' // Sentry library
import { BrowserTracing } from '@sentry/tracing'
import store from './store'
import globalComponents from './global-components'
import './libs'
import utils from './utils'
// SASS Theme
import './assets/sass/app.scss'

// primeVue
import PrimeVue from 'primevue/config'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primevue/resources/themes/saga-blue/theme.css'
import AutoComplete from 'primevue/autocomplete'
import Dropdown from 'primevue/dropdown'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
const app = createApp(App)
  .use(store)
  .use(router)
  .use(PrimeVue)
  .use(ToastService)
  .component('Toast', Toast)
  .component('AutoComplete', AutoComplete)
  .component('Dropdown', Dropdown)
globalComponents(app)
utils(app)

Sentry.init({
  app,
  dsn: 'https://dbc9ae9da3bc41c9958c26e47faa5dbf@o1327108.ingest.sentry.io/6587854',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'gama.docode.com.mx', /^\//],
      logErrors: true
    })
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})

app.mount('#app')
