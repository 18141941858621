import Service from './Service'

const resourceLogin = 'empleados/login/'
const resourceLogout = 'empleados/logout/'

export default {
  login(data) {
    return Service.post(resourceLogin, {
      email: data.email,
      password: data.password
    })
  },
  logOut(token) {
    return Service.delete(resourceLogout, {
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }
}
