<template>
  <div class="intro-y box p-5 mt-5">
    <div
      class="
        flex flex-col
        sm:flex-col sm:items-end
        xl:items-start xl:justify-beetwen
      "
    >
      <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto mb-5">
        <div class="sm:flex items-center sm:mr-4">
          <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
            >Field</label
          >
          <select
            id="tabulator-html-filter-field"
            v-model="filter.field"
            class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto"
          >
            <option value="Anio">Año</option>
            <option value="Folio">Folio</option>
            <option value="Fecha">Fecha</option>
            <option value="Cliente">Cliente</option>
            <option value="Expediente">Expediente</option>
            <option value="InmuebleClave">Clave</option>
            <option value="Estatus">Estatus</option>
            <option value="Contacto">Contacto</option>
            <option value="InmuebleCalle">Calle</option>
            <option value="InmuebleColonia">Colonia</option>
            <option value="InmuebleMunicipio">Municipio</option>
            <option value="InmuebleEstado">Estado</option>
            <option value="InmuebleCP">CP</option>
            <option value="FechaEntrega">Fecha Entrega</option>
            <option value="DiasParaEntregar">Dias Para Entregar</option>
          </select>
        </div>
        <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
          <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
            >Value</label
          >
          <input
            id="tabulator-html-filter-value"
            v-model="filter.value"
            type="text"
            class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
            placeholder="Search..."
          />
        </div>
        <div class="sm:flex items-center sm:mr-4">
          <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
            >Campo 2</label
          >
          <select
            id="tabulator-html-filter-field"
            v-model="filter.field2"
            class="form-select w-full sm:w-32 2xl:w-full mt-2 sm:mt-0 sm:w-auto"
          >
            <option value="Anio">Año</option>
            <option value="Folio">Folio</option>
            <option value="Fecha">Fecha</option>
            <option value="Cliente">Cliente</option>
            <option value="Expediente">Expediente</option>
            <option value="InmuebleClave">Clave</option>
            <option value="Estatus">Estatus</option>
            <option value="Contacto">Contacto</option>
            <option value="InmuebleCalle">Calle</option>
            <option value="InmuebleColonia">Colonia</option>
            <option value="InmuebleMunicipio">Municipio</option>
            <option value="InmuebleEstado">Estado</option>
            <option value="InmuebleCP">CP</option>
            <option value="FechaEntrega">Fecha Entrega</option>
            <option value="DiasParaEntregar">Dias Para Entregar</option>
          </select>
        </div>
        <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
          <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
            >Value</label
          >
          <input
            id="tabulator-html-filter-value"
            v-model="filter.value2"
            type="text"
            class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
            placeholder="Search..."
          />
        </div>
        <div class="mt-2 xl:mt-0">
          <button
            id="tabulator-html-filter-go"
            type="button"
            class="btn btn-primary w-full sm:w-16"
            @click="onFilter"
          >
            Go
          </button>
          <button
            id="tabulator-html-filter-reset"
            type="button"
            class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
            @click="onResetFilter"
          >
            Reset
          </button>
        </div>
      </form>
      <div class="flex mt-5 sm:mt-0">
        <button
          id="tabulator-print"
          class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
          @click="onPrint"
        >
          <PrinterIcon class="w-4 h-4 mr-2" /> Print
        </button>
        <div class="dropdown w-1/2 sm:w-auto">
          <button
            class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
            aria-expanded="false"
          >
            <FileTextIcon class="w-4 h-4 mr-2" /> Export
            <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
          </button>
          <div class="dropdown-menu w-40">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a
                id="tabulator-export-csv"
                href="javascript:;"
                class="
                  flex
                  items-center
                  block
                  p-2
                  transition
                  duration-300
                  ease-in-out
                  bg-white
                  dark:bg-dark-1
                  hover:bg-gray-200
                  dark:hover:bg-dark-2
                  rounded-md
                "
                @click="onExportCsv"
              >
                <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-x-auto scrollbar-hidden">
      <div
        id="tabulator"
        ref="tableRef"
        class="mt-5 table-report table-report--tabulator"
      ></div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, ref } from 'vue'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
export default defineComponent({
  props: {
    column: {
      type: Array,
      default: () => {}
    },
    apiCall: {
      type: Function,
      default: () => {}
    },
    filterHeader: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const svg =
      "<svg version='1.1' id='Capa_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='144.13px' height='151px' viewBox='0 0 144.13 151' enable-background='new 0 0 144.13 151' xml:space='preserve'> <path class='st0' d='M90.392,53.699c0.088-3.599,0.055-7.268,0.022-10.815l-0.012-1.428c-0.008-0.925-0.364-1.775-1.001-2.39 c-0.597-0.577-1.38-0.884-2.198-0.861c-1.809,0.047-3.027,1.359-3.032,3.265c-0.006,2.74,0.005,5.525,0.015,8.219l0.01,2.468 c0.003,0.989-0.026,2.156-0.259,3.314c-0.404,2.004-2.3,3.306-4.317,2.964c-1.991-0.338-3.185-1.859-3.195-4.071 c-0.007-1.652-0.005-3.305-0.002-4.956c0.004-2.635,0.009-5.359-0.031-8.035c-0.013-0.918-0.354-1.746-0.959-2.332 c-0.571-0.554-1.33-0.852-2.158-0.836c-1.77,0.031-3.018,1.374-3.036,3.265c-0.014,1.459-0.011,2.953-0.008,4.534 c0.002,0.746,0.004,1.526,0.004,2.352c0.047,0.808,0.016,1.654-0.016,2.549c-0.056,1.58-0.115,3.214,0.246,4.8 c1.079,4.732,5.706,8.098,10.542,7.667C86.319,62.896,90.266,58.828,90.392,53.699'/><path class='st0' d='M76.979,30.439c-0.016,2.038-1.523,3.512-3.581,3.501c-1.983-0.01-3.487-1.583-3.466-3.627 c0.02-1.949,1.594-3.448,3.592-3.419C75.526,26.922,76.995,28.429,76.979,30.439'/><path class='st0' d='M74.202,11.923c-8.741,0.157-15.658,7.488-15.658,16.231v10.23c0,0.775,0.001,1.55,0.003,2.325 c0.005,3,0.01,6.103-0.069,9.132c-0.128,4.862-0.075,9.598,1.935,14.078c2.804,6.249,8.54,10.608,15.149,11.745 c1.796,0.309,3.444-1.056,3.444-2.879c0-1.424-1.03-2.631-2.433-2.879c-4.686-0.827-8.865-3.986-10.835-8.377 c-1.507-3.36-1.534-7.374-1.425-11.536c0.081-3.11,0.076-6.255,0.071-9.295c-0.002-0.771-0.003-1.543-0.003-2.314V28.102 c0-5.513,4.313-10.186,9.823-10.341c5.711-0.161,10.407,4.436,10.407,10.111v3.181c0,1.612,1.307,2.918,2.918,2.918 c1.612,0,2.919-1.306,2.919-2.918v-3.181C90.448,18.979,83.132,11.761,74.202,11.923'/></svg>"
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'Anio',
      type: 'like',
      value: '',
      field2: 'Anio',
      type2: 'like',
      value2: ''
    })
    const filterBoolean = ref(props.filterHeader)
    const initTabulator = (columns, peticionapi) => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: 'https://qa.iusper.com/employees/list',
        ajaxRequestFunc: peticionapi,
        ajaxContentType: 'json',
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        locale: 'es-mx',
        ajaxLoader: true,
        ajaxLoaderLoading: svg,
        langs: {
          'es-mx': {
            pagination: {
              page_size: 'Tamaño de Página'
            }
          }
        },
        columns: columns,
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }
    // Filter function
    const onFilter = (e) => {
      e.preventDefault()
      tabulator.value.setFilter([
        { field: filter.field, type: filter.type, value: filter.value },
        { field: filter.field2, type: filter.type2, value: filter.value2 }
      ])
    }

    // On reset filter
    const onResetFilter = (e) => {
      filter.field = 'name'
      filter.type = 'like'
      filter.value = ''
      filter.field2 = 'name'
      filter.type2 = 'like'
      filter.value2 = ''
      onFilter(e)
    }
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }
    onMounted(() => {
      initTabulator(props.column, props.apiCall)
      reInitOnResizeWindow()
    })
    return {
      tableRef,
      filter,
      filterBoolean,
      onFilter,
      onResetFilter,
      initTabulator,
      reInitOnResizeWindow,
      tabulator,
      onExportCsv,
      onPrint
    }
  }
})
</script>

<style scoped>
</style>
