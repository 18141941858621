<template>
  <div>
    <!-- BEGIN: Wizard Layout -->
    <div class="intro-y box py-10 sm:py-20 mt-5">
      <div
        class="wizard flex flex-col lg:flex-row lg:flex-wrap justify-center px-2 sm:px-3"
      >
        <div
          class="intro-x lg:text-center flex items-center lg:block flex-1 z-10"
        >
          <button
            @click="StepByStep = 'Identificación'"
            class="w-10 h-10 rounded-full btn"
            :class="
              StepByStep == 'Identificación'
                ? 'btn-primary'
                : 'bg-gray-200'
            "
          >
            1
          </button>
          <div class="lg:w-32 font-medium text-base lg:mt-3 ml-3 lg:mx-auto">
            Identificación
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Datos del Controlador'"
            class="w-10 h-10 rounded-full btn"
            :class="
              StepByStep == 'Datos del Controlador' ? 'btn-primary' : 'bg-gray-200'
            "
          >
            2
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Datos del Controlador
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Datos del Valuador Profesional'"
            class="w-10 h-10 rounded-full btn"
            :class="
              StepByStep == 'Datos del Valuador Profesional' ? 'btn-primary' : 'bg-gray-200'
            "
          >
            3
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Datos del Valuador Profesional
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Información del Solicitante'"
            class="w-10 h-10 rounded-full btn"
            :class="StepByStep == 'Información del Solicitante' ? 'btn-primary' : 'bg-gray-200'"
          >
            4
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Información del Solicitante
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Información del Propietario'"
            class="w-10 h-10 rounded-full btn"
            :class="StepByStep == 'Información del Propietario' ? 'btn-primary' : 'bg-gray-200'"
          >
            5
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Información del Propietario
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Datos Generales'"
            class="w-10 h-10 rounded-full btn"
            :class="StepByStep == 'Datos Generales' ? 'btn-primary' : 'bg-gray-200'"
          >
            6
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Datos Generales
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Información General del Inmueble'"
            class="w-10 h-10 rounded-full btn"
            :class="StepByStep == 'Información General del Inmueble' ? 'btn-primary' : 'bg-gray-200'"
          >
            7
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Información General del Inmueble
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Dirección del inmueble y domicilio Geográfico'"
            class="w-10 h-10 rounded-full btn"
            :class="StepByStep == 'Dirección del inmueble y domicilio Geográfico' ? 'btn-primary' : 'bg-gray-200'"
          >
            8
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Dirección del inmueble y domicilio Geográfico
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Documentación'"
            class="w-10 h-10 rounded-full btn"
            :class="StepByStep == 'Documentación' ? 'btn-primary' : 'bg-gray-200'"
          >
            9
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Documentación
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Características Urbanas'"
            class="w-10 h-10 rounded-full btn"
            :class="StepByStep == 'Características Urbanas' ? 'btn-primary' : 'bg-gray-200'"
          >
            10
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Características Urbanas
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Infraestructura y Servicios Disponibles en la zona'"
            class="w-10 h-10 rounded-full btn"
            :class="StepByStep == 'Infraestructura y Servicios Disponibles en la zona' ? 'btn-primary' : 'bg-gray-200'"
          >
            11
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Infraestructura y Servicios Disponibles en la zona
          </div>
        </div>
        <div
          class="
            intro-x
            lg:text-center
            flex
            items-center
            mt-5
            lg:mt-0 lg:block
            flex-1
            z-10
          "
        >
          <button
            @click="StepByStep = 'Equipamiento Urbano'"
            class="w-10 h-10 rounded-full btn"
            :class="StepByStep == 'Equipamiento Urbano' ? 'btn-primary' : 'bg-gray-200'"
          >
            12
          </button>
          <div
            class="
              lg:w-32
              text-base
              lg:mt-3
              ml-3
              lg:mx-auto
              text-gray-700
              dark:text-gray-600
            "
          >
            Equipamiento Urbano
          </div>
        </div>
      </div>
      <PanelIdentificacion
        v-if="StepByStep == 'Identificación'"
        @CambioPanel="CambioPanel($event)"
      />
    </div>
    <!-- END: Wizard Layout -->
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import PanelIdentificacion from './Paneles/Identificacion/Main.vue'
export default defineComponent({
  components: {
    PanelIdentificacion
  },
  setup() {
    const StepByStep = ref('Identificación')
    const CambioPanel = (panel) => {
      StepByStep.value = panel
    }
    return {
      StepByStep,
      CambioPanel
    }
  }
})
</script>

<style scoped>
</style>
