<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Facturas</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#superlarge-slide-over-size-preview"
          class="btn btn-primary mr-1 mb-2"
          @click="limpiarSlider"
          >Nueva Factura</a
        >
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <tabla ref="tableComponent" :column="column" :apiCall="apiCall"></tabla>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Super Large Slide Over Content -->
    <div
      id="superlarge-slide-over-size-preview"
      class="modal modal-slide-over"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <h2 class="font-medium text-base mr-auto">Nuevo Registro</h2>
          </div>
          <div class="modal-body">
            <form class="container">
              <div class="font-medium text-lg">
                Folio / Fecha
                <div class="grid grid-cols-12 gap-2 p-2">
                  <div class="col-span-6">
                    <label class="col-span-12">Folio Solicitud</label>
                    <input
                      v-model="Folio"
                      type="text"
                      class="form-control col-span-6"
                      placeholder="Folio Solicitud"
                      aria-label="Folio Solicitud"
                      disabled
                    />
                  </div>
                  <div class="col-span-6">
                    <label class="col-span-12">Fecha Creación</label>
                    <input
                      type="date"
                      v-model="Fecha"
                      class="form-control col-span-6"
                    />
                  </div>
                </div>
                <div class="grid grid-cols-12 gap-2 p-2">
                  <div class="col-span-6">
                    <label class="col-span-12">Factura Fiscal</label>
                    <input
                      v-model="facturaFiscal"
                      type="text"
                      class="form-control col-span-6"
                      placeholder="Factura Fiscal"
                      aria-label="Factura Fiscal"
                    />
                  </div>
                  <div class="col-span-6">
                    <div class="font-medium text-lg">
                      Solicitud
                      <AutoComplete
                        v-model="Folio"
                        :suggestions="filteredSolicitud"
                        :dropdown="true"
                        @complete="searchSolicitud($event)"
                        @item-select="SeleccionSolicitud($event)"
                        field="Folio"
                        style="width: 100%"
                        inputStyle="width: 100%"
                        class="col-span-12"
                        placeholder="Solicitud"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="font-medium text-lg">
                Solicitante
                <AutoComplete
                  v-model="Solicitante"
                  :suggestions="filteredsolicitante"
                  :dropdown="true"
                  field="Nombre"
                  @complete="searchSolicitante($event)"
                  @item-select="SeleccionSolicitante($event)"
                  style="width: 100%"
                  inputStyle="width: 100%"
                  class="p-2 col-span-12"
                  placeholder="Solicitante"
                />
              </div>
              <div class="font-medium text-lg">
                <div class="grid grid-cols-12 gap-2 p-2">
                  <div class="grid grid-cols-12 col-span-6">
                    <label class="col-span-12">Estatus</label>
                    <input
                      v-model="Estatus"
                      type="text"
                      class="form-control col-span-12"
                      placeholder="Estatus"
                      aria-label="Estatus"
                    />
                  </div>
                  <div class="grid grid-cols-12 col-span-6">
                    <label class="col-span-12">Subtotal</label>
                    <input
                      v-model="Importe"
                      type="text"
                      class="form-control col-span-12"
                      placeholder="Importe"
                      aria-label="Importe"
                      @change="OperacionImporte"
                    />
                  </div>
                </div>
              </div>
              <div class="font-medium text-lg">
                <div class="grid grid-cols-12 gap-2 p-2 justify-center">
                  <div class="col-span-4 grid grid-cols-12">
                    <label class="col-span-12">IVA: </label>
                    <div class="flex form-control col-span-6">
                      <input
                        v-model="IVA"
                        type="text"
                        class="w-full input-group-Number"
                        placeholder="IVA"
                        aria-label="IVA"
                        @change="OperacionImporte"
                      />
                      <span>{{ ' ' }}%</span>
                    </div>
                    <div class="flex form-control disabled col-span-6">
                      <span>{{ ' ' }}$</span>
                      <input
                        v-model="Impuesto"
                        type="text"
                        placeholder="Impuesto"
                        aria-label="Impuesto"
                        class="w-full input-group-Number"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-span-4 grid grid-cols-12">
                    <label class="col-span-12">R_ISR: </label>
                    <div class="flex form-control col-span-6">
                      <input
                        @change="validarRISR"
                        v-model="ISRPorcentaje"
                        type="text"
                        class="w-full input-group-Number"
                        placeholder="Porcentaje R_ISR"
                        aria-label="Porcentaje R_ISR"
                      />
                      <span>{{ ' ' }}%</span>
                    </div>
                    <div class="flex form-control disabled col-span-6">
                      <span>{{ ' ' }}$</span>
                      <input
                        v-model="R_ISR"
                        type="text"
                        class="w-full input-group-Number"
                        placeholder="R_ISR"
                        aria-label="R_ISR"
                        disabled
                      />
                    </div>
                  </div>
                  <div class="col-span-4 grid grid-cols-12">
                    <label class="col-span-12">R_IVA: </label>
                    <div class="flex form-control col-span-6">
                      <input
                        @change="validarRIVA"
                        v-model="IVAPorcentaje"
                        type="text"
                        class="w-full input-group-Number"
                        placeholder="Porcentaje R_IVA"
                        aria-label="Porcentaje R_IVA"
                      />
                      <span>{{ ' ' }}%</span>
                    </div>
                    <div class="flex form-control disabled col-span-6">
                      <span>{{ ' ' }}$</span>
                      <input
                        v-model="R_IVA"
                        type="text"
                        class="w-full input-group-Number"
                        placeholder="R_IVA"
                        aria-label="R_IVA"
                        disabled
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="font-medium text-lg">
                <div class="grid grid-cols-12 gap-2 p-2 justify-center">
                  <label class="col-span-12">Total</label>
                  <input
                    v-model="Total"
                    type="text"
                    class="form-control col-span-6"
                    placeholder="Total"
                    aria-label="Total"
                    disabled
                  />
                </div>
              </div>
              <div class="font-medium text-lg">
                <div class="grid grid-cols-12 gap-2 p-2 justify-center">
                  <input
                    v-model="Pagada"
                    type="text"
                    class="form-control col-span-6"
                    placeholder="Pagada"
                    aria-label="Pagada"
                  />
                  <input
                    v-model="ValorAvaluo"
                    type="text"
                    class="form-control col-span-6"
                    placeholder="Valor Avaluo"
                    aria-label="Valor Avaluo"
                    disabled
                  />
                </div>
              </div>
              <div class="font-medium text-lg">
                <div class="grid grid-cols-12 gap-2 p-2 justify-center">
                  <input
                    v-model="Der_Catastro"
                    type="text"
                    class="form-control col-span-6"
                    placeholder="Derechos Catastro"
                    aria-label="Derechos Catastro"
                    disabled
                  />
                  <input
                    v-model="FolioHacendario"
                    type="text"
                    class="form-control col-span-6"
                    placeholder="Folio Hacendario"
                    aria-label="Folio Hacendario"
                    disabled
                  />
                </div>
              </div>
              <div class="font-medium text-lg">
                <div class="grid grid-cols-12 gap-2 p-2 justify-center">
                  <input
                    v-model="Concepto"
                    type="text"
                    class="form-control col-span-12"
                    placeholder="Concepto"
                    aria-label="Concepto"
                  />
                </div>
              </div>
              <input
                v-if="nuevo"
                data-dismiss="modal"
                class="w-full h-10 px-5 bg-success"
                type="submit"
                value="Enviar"
                @click="crearFacturas"
              />
              <input
                v-else
                data-dismiss="modal"
                class="w-full h-10 px-5 bg-success"
                type="submit"
                value="Editar"
                @click="editarFacturas"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Super Large Slide Over Content -->
    <Toast />
    <!-- Begin: Modal Delete -->
    <div
      id="delete-modal-preview"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Estas Seguro?</div>
              <div class="text-gray-600 mt-2">
                Desea eliminar el id {{ Folio }} Total {{ Total }} ?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 dark:border-dark-5 dark:text-gray-300 mr-1"
              >
                Cancel
              </button>
              <button
                data-dismiss="modal"
                type="button"
                class="btn btn-danger w-24"
                @click="eliminarFacturas"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- willy aqui tienes que poner los estilos del html y despues pasarte a la linea de la funcion para que en la variable css puedas poner el css correspondiente -->
    <div ref="print" class="hidden">
      <div class="box">
        <div class="space right">{{ Fecha }}</div>
        <div class="space right">{{ Folio }}</div>

        <div class="mitad">
          <div class="space">{{ Solicitante }}</div>
          <div class="space">{{ RFCSolicitante }}</div>
          <div v-if="Concepto != null" class="space">
            {{
              Concepto.replace('AVALUO REALIZADO AL INMUEBLE UBICADO EN', '')
            }}
          </div>
        </div>

        <div class="mitadder">
          <div class="space">{{ Concepto }}</div>
        </div>

        <div class="space right">{{ Importe }}</div>
        <div class="space right">{{ Impuesto }}</div>
        <div class="space right">{{ R_ISR }}</div>
        <div class="space right">{{ R_IVA }}</div>
        <div class="space right">{{ TotalImpresion }}</div>
        <div class="space">({{ convertNumber() }})</div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRefs,
  computed
} from 'vue'
import todoFacturas from '../../apiaccess/todoFacturas'
import todoSolicitudes from '../../apiaccess/todoSolicitudes'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import tabla from '@/global-components/tablaGeneral/Main.vue'
import { Printd } from 'printd'
import NumeroLetra from './NumeroLetra'
export default defineComponent({
  setup() {
    // Formato Moneda
    const options = { style: 'currency', currency: 'MXN' }
    const formatMoneda = new Intl.NumberFormat('es-MX', options)
    /* Variables Api */
    const Solicitudes = ref(null)
    const Solicitante = ref(null)
    const filteredSolicitud = ref(null)
    const filteredsolicitante = ref(null)
    /* Variables Toast */
    const toast = useToast()
    /* variables para llamada api */
    const nuevo = ref(true)
    /* variables para token y store */
    const store = useStore()
    const token = ref(store.getters['firebase/GetToken'])
    /* variables para tabla */
    const tableComponent = ref()
    const column = [
      {
        formatter: 'responsiveCollapse',
        width: 40,
        minWidth: 30,
        hozAlign: 'center',
        resizable: false,
        headerSort: false
      },
      // For HTML table
      {
        title: 'Factura',
        minWidth: 100,
        responsive: 0,
        field: 'facturaFiscal',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Fecha',
        minWidth: 100,
        responsive: 0,
        field: 'Fecha',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true,
        headerSort: false
      },
      {
        title: 'Solicitante',
        minWidth: 250,
        responsive: 0,
        field: 'Solicitante',
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: true,
        download: true
      },
      {
        title: 'Folio',
        minWidth: 100,
        responsive: 0,
        field: 'Folio',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Importe',
        minWidth: 100,
        responsive: 0,
        field: 'Importe',
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: true,
        download: true,
        formatter(cell) {
          const dato = cell.getData().Importe
          return formatMoneda.format(dato)
        }
      },
      {
        title: 'IVA',
        minWidth: 100,
        responsive: 0,
        field: 'Impuesto',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true,
        formatter(cell) {
          const dato = cell.getData().Impuesto
          return formatMoneda.format(dato)
        }
      },
      {
        title: 'R_ISR',
        minWidth: 100,
        responsive: 0,
        field: 'R_ISR',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true,
        formatter(cell) {
          const dato = cell.getData().R_ISR
          return formatMoneda.format(dato)
        }
      },
      {
        title: 'R_IVA',
        minWidth: 100,
        responsive: 0,
        field: 'R_IVA',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true,
        formatter(cell) {
          const dato = cell.getData().R_IVA
          return formatMoneda.format(dato)
        }
      },
      {
        title: 'Total',
        minWidth: 150,
        responsive: 0,
        field: 'Total',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Cliente',
        minWidth: 120,
        responsive: 0,
        field: 'SolicitudCliente',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Catastro',
        minWidth: 150,
        responsive: 0,
        field: 'Der_Catastro',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'F. Hacendario',
        minWidth: 150,
        responsive: 0,
        field: 'FolioHacendario',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'ACTIONS',
        minWidth: 246,
        field: 'actions',
        responsive: 0,
        hozAlign: 'center',
        vertAlign: 'middle',
        headerSort: false,
        print: false,
        download: false,
        columns: [
          {
            title: 'Editar',
            width: 80,
            field: 'Editar',
            hozAlign: 'center',
            headerSort: false,
            responsive: 0,
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#superlarge-slide-over-size-preview" class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i>
                </a>
                </div>`
            },
            cellClick: function(e, cell) {
              nuevo.value = false
              slider.id = cell.getData().id
              slider.Folio = cell.getData().Folio
              slider.FolioSolicitud = cell.getData().FolioSolicitud
              slider.SolicitudCliente = cell.getData().SolicitudCliente
              slider.Solicitante = cell.getData().Solicitante
              slider.Estatus = cell.getData().Estatus
              slider.Importe = cell.getData().Importe
              slider.Impuesto = cell.getData().Impuesto
              slider.R_ISR = cell.getData().R_ISR
              slider.R_IVA = cell.getData().R_IVA
              slider.Total = cell.getData().Total
              slider.Pagada = cell.getData().Pagada
              slider.ValorAvaluo = cell.getData().ValorAvaluo
              slider.Der_Catastro = cell.getData().Der_Catastro
              slider.FolioHacendario = cell.getData().FolioHacendario
              slider.Concepto = cell.getData().Concepto
              slider.facturaFiscal = cell.getData().facturaFiscal
            }
          },
          {
            title: 'Eliminar',
            width: 100,
            hozAlign: 'center',
            field: 'Eliminar',
            headerSort: false,
            responsive: 0,
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#delete-modal-preview" class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="trash-2" class="w-4 h-4 mr-1"></i>
                </a>
                </div>`
            },
            cellClick: function(e, cell) {
              slider.id = cell.getData().id
              slider.Folio = cell.getData().Folio
              slider.Cliente = cell.getData().Cliente
            }
          },
          {
            title: 'Descargar',
            width: 100,
            hozAlign: 'center',
            field: 'Descargar',
            headerSort: false,
            responsive: 0,
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <i data-feather="printer" class="w-4 h-4 mr-1"></i>
                </div>`
            },
            cellClick: function(e, cell) {
              slider.Fecha = cell.getData().Fecha
              slider.Folio = cell.getData().Folio
              slider.Solicitante = cell.getData().Solicitante.split(' -- ')[0]
              slider.RFCSolicitante = cell
                .getData()
                .Solicitante.split(' -- ')[1]
              slider.Concepto = cell.getData().Concepto
              slider.Importe = formatMoneda.format(cell.getData().Importe)
              slider.Impuesto = formatMoneda.format(cell.getData().Impuesto)
              slider.R_ISR = formatMoneda.format(cell.getData().R_ISR)
              slider.R_IVA = formatMoneda.format(cell.getData().R_IVA)
              slider.TotalImpresion = cell.getData().Total
              printFactura()
            }
          }
        ]
      }
    ]
    // Conversion a numero
    const convertNumber = () => {
      if (slider.TotalImpresion != null) {
        const dato = parseFloat(
          slider.TotalImpresion.replace('$', '').replace(',', '')
        )
        const datoLetra = NumeroLetra.numeroLetra(dato, {
          plural: 'Pesos',
          singular: 'Peso',
          centPlural: 'centavos',
          centSingular: 'centavo'
        })
        return datoLetra
      } else {
        return '--'
      }
    }
    const apiCall = (url, config, params) => {
      return new Promise(function(resolve, reject) {
        todoFacturas
          .get(token.value, params)
          .then(data => {
            resolve(data.data)
          })
          .catch(error => {
            reject(error)
          })
      })
    }
    /* variables para el slider */
    const slider = reactive({
      id: null,
      Folio: null,
      facturaFiscal: null,
      FolioSolicitud: null,
      Fecha: new Date(new Date().toString().split('GMT')[0] + ' UTC')
        .toISOString()
        .split('T')[0],
      SolicitudCliente: null,
      Solicitante: null,
      RFCSolicitante: null,
      Estatus: 'Emitida',
      Importe: null,
      Impuesto: null,
      R_ISR: null,
      R_IVA: null,
      TotalImpresion: null,
      Total: computed(() => {
        let imp = 0
        let iva = 0
        let rIsr = 0
        let rIva = 0
        if (!isNaN(slider.Importe)) {
          imp = parseFloat(slider.Importe)
        }
        if (!isNaN(slider.Impuesto)) {
          iva = parseFloat(slider.Impuesto)
        }
        if (slider.R_ISR != null) {
          rIsr = parseFloat(slider.R_ISR)
        }
        if (slider.R_IVA != null) {
          rIva = parseFloat(slider.R_IVA)
        }
        let total = imp + iva - rIsr - rIva
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 2
        })
        total = formatter.format(total)
        return total
      }),
      Pagada: null,
      ValorAvaluo: null,
      Der_Catastro: null,
      FolioHacendario: null,
      Concepto: null,
      IVAPorcentaje: null,
      ISRPorcentaje: null,
      IVA: 16
    })
    const idUpdate = ref(null)
    /* Funciones para el slider */
    const searchSolicitud = event => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredSolicitud.value = [...Solicitudes.value]
        } else {
          filteredSolicitud.value = Solicitudes.value.filter(solicitud => {
            solicitud.Folio = solicitud.Folio.toString()
            return solicitud.Folio.toLowerCase().startsWith(
              event.query.toLowerCase()
            )
          })
        }
      }, 250)
    }
    const searchSolicitante = event => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredsolicitante.value = [...Solicitante.value]
          filteredsolicitante.value = filteredsolicitante.value.map(filtro => {
            return {
              Nombre: filtro.Nombre,
              RFC: filtro.RFC,
              CURP: filtro.CURP,
              Folio: filtro.Folio
            }
          })
        } else {
          filteredsolicitante.value = Solicitante.value.filter(solicitud => {
            return solicitud.Nombre.toLowerCase().startsWith(
              event.query.toLowerCase()
            )
          })
          filteredsolicitante.value = filteredsolicitante.value.map(filtro => {
            return {
              Nombre: filtro.Nombre,
              RFC: filtro.RFC,
              CURP: filtro.CURP
            }
          })
        }
      }, 250)
    }
    const SeleccionSolicitud = event => {
      idUpdate.value = event.value.id
      slider.SolicitudCliente = event.value.Cliente
      slider.Folio = event.value.Folio
      Solicitante.value = event.value.Solicitantes
      slider.ValorAvaluo = event.value.ValorAvaluo
      slider.Der_Catastro = event.value.Der_Catastro
      slider.FolioHacendario = event.value.FolioHacendario
      slider.Concepto = `AVALUO REALIZADO AL INMUEBLE UBICADO EN ${
        event.value.InmuebleCalle !== null ? event.value.InmuebleCalle : ''
      } # ${
        event.value.InmuebleNumExt !== null ? event.value.InmuebleNumExt : ''
      } int ${
        event.value.InmuebleNumInt !== null ? event.value.InmuebleNumInt : ''
      } ${
        event.value.InmuebleColonia !== null ? event.value.InmuebleColonia : ''
      } ${
        event.value.InmuebleEstado !== null ? event.value.InmuebleEstado : ''
      } ${
        event.value.InmuebleMunicipio !== null
          ? event.value.InmuebleMunicipio
          : ''
      } ${event.value.InmuebleCP !== null ? event.value.InmuebleCP : ''}`
    }
    const SeleccionSolicitante = event => {
      slider.Solicitante = event.value.Nombre + ' -- RFC: ' + event.value.RFC
    }
    const OperacionImporte = () => {
      const valor = parseFloat(slider.Importe).toFixed(2)
      const iva =
        slider.IVA !== 16
          ? (valor * parseInt(slider.IVA)) / 100
          : (valor * 16).toFixed(2) / 100
      slider.Impuesto = iva.toFixed(2)
    }
    const validarRISR = () => {
      const valor = parseFloat(slider.Importe).toFixed(2)
      const isr = parseFloat(slider.ISRPorcentaje)
      const rIsr = (valor * isr) / 100
      slider.R_ISR = rIsr.toFixed(3)
    }
    const validarRIVA = () => {
      const valor = parseFloat(slider.Importe).toFixed(2)
      const iva = parseFloat(slider.IVAPorcentaje)
      const riva = (valor * iva) / 100
      slider.R_IVA = riva.toFixed(3)
    }
    const crearFacturas = async e => {
      e.preventDefault()
      try {
        await todoFacturas.post(token.value, slider)
        await todoSolicitudes.patch(
          token.value,
          {
            Factura: slider.facturaFiscal
          },
          idUpdate.value
        )
        tableComponent.value.tabulator.replaceData()
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Se creo Correctamente el Valor',
          life: 3000
        })
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response,
          life: 3000
        })
        console.error(error)
      }
    }
    const editarFacturas = async e => {
      e.preventDefault()
      try {
        await todoFacturas.patch(token.value, slider, slider.id)
        tableComponent.value.tabulator.replaceData()
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Se creo Correctamente el Valor',
          life: 3000
        })
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response,
          life: 3000
        })
        console.error(error)
      }
    }
    /* Elimina Factura */
    const eliminarFacturas = async () => {
      try {
        await todoFacturas.delete(token.value, slider.id)
        toast.add({
          severity: 'success',
          summary: 'success Message',
          detail: 'Se elimino correctamente el valor',
          life: 3000
        })
        tableComponent.value.tabulator.replaceData()
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response.data,
          life: 3000
        })
      }
    }
    /* Limpia los campos al abrir nuevo valor */
    const limpiarSlider = () => {
      nuevo.value = true
      slider.id = null
      slider.facturaFiscal = null
      slider.Folio = null
      slider.FolioSolicitud = null
      slider.Fecha = new Date(new Date().toString().split('GMT')[0] + ' UTC')
        .toISOString()
        .split('T')[0]
      slider.SolicitudCliente = null
      slider.Solicitante = null
      slider.Estatus = 'Emitida'
      slider.Importe = null
      slider.Impuesto = null
      slider.R_ISR = null
      slider.R_IVA = null
      slider.Pagada = 'Pagada'
      slider.ValorAvaluo = null
      slider.Der_Catastro = null
      slider.FolioHacendario = null
      slider.Concepto = null
    }
    /* TODO LO REFERENTE A IMPRESION FACTURA ELIAS WILLY TQM, modifica este cssText, borra las clases y pon las nuevas */
    const cssText = `
                    .box{
                      position: relative;
                      /* padding: 50px 30px 30px 130px; */
                      padding: 50px 30px 30px 30px;
                      font-size:13px;
                      text-transform:uppercase;
                    }
                    .mitad{
                      width: 240px;
                      margin: 0 auto;
                    }
                    .mitadder{
                      width: 490px;
                      margin: 0 0 0 auto;
                    }
                    .right{
                      text-align:end;
                    }
                    .space{
                      margin: 40px 0;
                    }
                    `
    const print = ref('')
    const printFactura = () => {
      setTimeout(() => {
        const d = new Printd()
        d.print(print.value, [cssText])
      }, 1000)
    }
    onMounted(() => {
      const infinito = {
        filters: [],
        page: 1,
        size: 10000,
        sorters: []
      }
      todoSolicitudes.get(token.value, infinito).then(data => {
        Solicitudes.value = data.data.data
        return Solicitudes.value
      })
    })
    return {
      ...toRefs(slider),
      crearFacturas,
      limpiarSlider,
      eliminarFacturas,
      editarFacturas,
      nuevo,
      filteredSolicitud,
      searchSolicitud,
      SeleccionSolicitud,
      filteredsolicitante,
      searchSolicitante,
      SeleccionSolicitante,
      OperacionImporte,
      validarRISR,
      validarRIVA,
      tableComponent,
      column,
      apiCall,
      print,
      convertNumber
    }
  },
  components: {
    tabla
  }
})
</script>
