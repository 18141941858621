<template>
  <div>
    <div
      class="
        px-5
        sm:px-20
        mt-10
        pt-10
        border-t border-gray-200
        dark:border-dark-5
      "
    >
      <div class="font-medium text-base">Información General</div>
      <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-date" class="form-label"
            >Fecha de Inspección</label
          >
          <input id="input-wizard-date" type="date" class="form-control" />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-tipoAvaluo" class="form-label"
            >Tipo de Avalúo</label
          >
          <input
            id="input-wizard-tipoAvaluo"
            type="text"
            class="form-control"
            placeholder="Tipo de Avalúo"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-FolioSolicitante" class="form-label"
            >Folio Solicitante</label
          >
          <input
            id="input-wizard-FolioSolicitante"
            type="text"
            class="form-control"
            placeholder="Folio Solicitante"
          />
        </div>
        <div class="intro-y col-span-12 sm:col-span-6">
          <label for="input-wizard-Estatus" class="form-label">Estatus</label>
          <input
            id="input-wizard-Estatus"
            type="text"
            class="form-control"
            placeholder="Estatus"
          />
        </div>
        <div
          class="
            intro-y
            col-span-12
            flex
            items-center
            justify-center
            sm:justify-end
            mt-5
          "
        >
          <button
            @click="SaveInformacionGeneral"
            class="btn btn-primary w-36 ml-2"
          >
            Guardar y Continuar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup(props, { emit }) {
    const SaveInformacionGeneral = () => {
      emit('CambioPanel', 'Usuarios y Roles')
    }
    return {
      SaveInformacionGeneral
    }
  }
})
</script>

<style scoped>
</style>
