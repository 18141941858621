<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Entregas</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#superlarge-slide-over-size-preview"
          class="btn btn-primary mr-1 mb-2"
          @click="limpiarSlider"
          >Nueva Entrega</a
        >
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <div class="intro-y box p-5 mt-5">
      <div class="flex flex-col sm:flex-row sm:items-end xl:items-start">
        <form id="tabulator-html-filter-form" class="xl:flex sm:mr-auto">
          <div class="sm:flex items-center sm:mr-4">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Field</label
            >
            <select
              id="tabulator-html-filter-field"
              class="
                form-select
                w-full
                sm:w-32
                2xl:w-full
                mt-2
                sm:mt-0 sm:w-auto
              "
            >
              <option value="name">Name</option>
              <option value="category">Category</option>
              <option value="remaining_stock">Remaining Stock</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Type</label
            >
            <select
              id="tabulator-html-filter-type"
              class="form-select w-full mt-2 sm:mt-0 sm:w-auto"
            >
              <option value="like" selected>like</option>
              <option value="=">=</option>
              <option value="<">&lt;</option>
              <option value="<=">&lt;=</option>
              <option value=">">></option>
              <option value=">=">>=</option>
              <option value="!=">!=</option>
            </select>
          </div>
          <div class="sm:flex items-center sm:mr-4 mt-2 xl:mt-0">
            <label class="w-12 flex-none xl:w-auto xl:flex-initial mr-2"
              >Value</label
            >
            <input
              id="tabulator-html-filter-value"
              type="text"
              class="form-control sm:w-40 2xl:w-full mt-2 sm:mt-0"
              placeholder="Search..."
            />
          </div>
          <div class="mt-2 xl:mt-0">
            <button
              id="tabulator-html-filter-go"
              type="button"
              class="btn btn-primary w-full sm:w-16"
              @click="onFilter"
            >
              Go
            </button>
            <button
              id="tabulator-html-filter-reset"
              type="button"
              class="btn btn-secondary w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1"
              @click="onResetFilter"
            >
              Reset
            </button>
          </div>
        </form>
        <div class="flex mt-5 sm:mt-0">
          <button
            id="tabulator-print"
            class="btn btn-outline-secondary w-1/2 sm:w-auto mr-2"
            @click="onPrint"
          >
            <PrinterIcon class="w-4 h-4 mr-2" /> Print
          </button>
          <div class="dropdown w-1/2 sm:w-auto">
            <button
              class="dropdown-toggle btn btn-outline-secondary w-full sm:w-auto"
              aria-expanded="false"
            >
              <FileTextIcon class="w-4 h-4 mr-2" /> Export
              <ChevronDownIcon class="w-4 h-4 ml-auto sm:ml-2" />
            </button>
            <div class="dropdown-menu w-40">
              <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
                <a
                  id="tabulator-export-csv"
                  href="javascript:;"
                  class="
                    flex
                    items-center
                    block
                    p-2
                    transition
                    duration-300
                    ease-in-out
                    bg-white
                    dark:bg-dark-1
                    hover:bg-gray-200
                    dark:hover:bg-dark-2
                    rounded-md
                  "
                  @click="onExportCsv"
                >
                  <FileTextIcon class="w-4 h-4 mr-2" /> Export CSV
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overflow-x-auto scrollbar-hidden">
        <div
          id="tabulator"
          ref="tableRef"
          class="mt-5 table-report table-report--tabulator"
        ></div>
      </div>
    </div>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Super Large Slide Over Content -->
    <div
      id="superlarge-slide-over-size-preview"
      class="modal modal-slide-over"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <h2 class="font-medium text-base mr-auto">Nuevo Registro</h2>
          </div>
          <div class="modal-body">
            <div class="grid grid-cols-12 gap-2 p-2">
              <div class="col-span-6">
                <div class="grid grid-cols-12 gap-2 chat">
                  <div
                    class="
                      col-span-2
                      flex-col
                      chat__tabs
                      nav nav-tabs
                      item-center
                    "
                    role="tablist"
                  >
                    <a
                      id="Alta-tab"
                      data-toggle="tab"
                      data-target="#Alta"
                      href="javascript:;"
                      class="py-2 rounded-md text-center active"
                      role="tab"
                      aria-controls="Alta"
                      aria-selected="true"
                      >Alta</a
                    >
                  </div>
                  <div class="col-span-10">
                    <div id="Alta" aria-labelledby="Alta-tab" class="tab-pane">
                      <div class="box-form">
                        <form>
                          <div class="font-medium text-lg">
                            <label class="col-span-12 text-xs">
                              Folio
                              <AutoComplete
                                v-model="Folio"
                                :suggestions="filteredSolicitante"
                                :dropdown="true"
                                @complete="searchSolicitante($event)"
                                @item-select="SeleccionSolicitante($event)"
                                style="width: 100%"
                                inputStyle="width: 100%"
                                field="Folio"
                                class="p-2"
                                placeholder="Folio"
                              />
                            </label>
                          </div>
                          <div class="font-medium text-lg">
                            <div class="grid grid-cols-12 gap-2 p-2">
                              <label class="col-span-12 text-xs">
                                Cliente
                                <input
                                  v-model="Cliente"
                                  type="text"
                                  class="form-control"
                                  placeholder="Cliente"
                                  aria-label="Cliente"
                                  disabled
                                />
                              </label>
                            </div>
                          </div>
                          <div class="font-medium text-lg">
                            <div class="grid grid-cols-12 gap-2 p-2">
                              <label class="col-span-12 text-xs">
                                Contacto
                                <input
                                  v-model="Contacto"
                                  type="text"
                                  class="form-control"
                                  placeholder="Contacto"
                                  aria-label="Contacto"
                                  disabled
                                />
                              </label>
                            </div>
                          </div>
                          <div class="font-medium text-lg">
                            <div class="grid grid-cols-12 gap-2 p-2">
                              <label class="col-span-6 text-xs">
                                Tipo Avaluo <span class="text-red-300">**</span>
                                <Dropdown
                                  v-model="TipoAvaluo"
                                  :options="type"
                                  optionLabel="name"
                                  :editable="true"
                                />
                              </label>
                              <label class="col-span-6 text-xs">
                                Avaluo Folio / Año
                                <input
                                  v-model="Avaluo"
                                  type="text"
                                  class="form-control col-span-6"
                                  placeholder="Avaluo"
                                  aria-label="Avaluo"
                                  disabled
                                />
                              </label>
                            </div>
                          </div>
                          <div class="font-medium text-lg">
                            <div class="grid grid-cols-12 gap-2 p-2">
                              <label class="col-span-12 text-xs">
                                Ubicación
                                <input
                                  v-model="Ubicacion"
                                  type="text"
                                  class="form-control"
                                  placeholder="Ubicación"
                                  aria-label="Ubicación"
                                  disabled
                                />
                              </label>
                            </div>
                          </div>
                          <div class="font-medium text-lg">
                            <div class="grid grid-cols-12 gap-2 p-2">
                              <label class="col-span-6 text-xs">
                                Folio Hacendario
                                <span class="text-red-300">**</span>
                                <input
                                  v-model="FolioHacendario"
                                  type="text"
                                  class="form-control"
                                  placeholder="Folio Hacendario"
                                  aria-label="Folio Hacendario"
                                />
                              </label>
                              <label class="col-span-6 text-xs">
                                Escritura
                                <input
                                  v-model="Escritura"
                                  type="text"
                                  class="form-control"
                                  placeholder="Escritura"
                                  aria-label="Escritura"
                                  disabled
                                />
                              </label>
                            </div>
                          </div>
                          <div class="font-medium text-lg">
                            <div class="grid grid-cols-12 gap-2 p-2">
                              <label class="col-span-6 text-xs">
                                Expediente
                                <input
                                  v-model="Expediente"
                                  type="text"
                                  class="form-control"
                                  placeholder="Expediente"
                                  aria-label="Expediente"
                                  disabled
                                />
                              </label>
                              <label class="col-span-6 text-xs">
                                Valor Avaluo
                                <input
                                  v-model="ValorAvaluo"
                                  type="text"
                                  class="form-control"
                                  placeholder="Valor Avaluo"
                                  aria-label="Valor Avaluo"
                                  @change="derechosCatastro($event)"
                                />
                              </label>
                            </div>
                          </div>
                          <div class="font-medium text-lg">
                            <div class="grid grid-cols-12 gap-2 p-2">
                              <label class="col-span-6 text-xs">
                                Derechos de Catastro
                                <input
                                  v-model="DerCatastro"
                                  type="text"
                                  class="form-control"
                                  placeholder="Derechos de Catastro"
                                  aria-label="Derechos de Catastro"
                                  disabled
                                />
                              </label>
                              <label class="col-span-6 text-xs">
                                Numero de Avaluos
                                <input
                                  v-model="NumAvaluos"
                                  type="number"
                                  class="form-control"
                                  placeholder="Numero de Avaluos"
                                  aria-label="Numero de Avaluos"
                                />
                              </label>
                            </div>
                            <div class="font-medium text-lg">
                              <input
                                class="w-full h-10 px-5 bg-success"
                                type="submit"
                                value="Añadir"
                                @click.prevent="agregarHoja"
                              />
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-6">
                <div class="box-preview">
                  <div ref="print" class="hoja overflow-y-auto">
                    <div class="box-title">
                      <h2>Entrega Avaluo</h2>
                    </div>
                    <div
                      v-for="element in elements"
                      :key="element.id"
                      class="font-medium text-lg"
                    >
                      <div class="box-title">
                        <h3 class="">{{ element.Cliente }}</h3>
                      </div>
                      <div class="grid grid-cols-12 gap-2 p-2">
                        <div
                          class="col-span-6 box-data-FC mbs-3 line-height-1-2"
                        >
                          Fecha: {{ element.Fecha }}
                        </div>
                        <div
                          class="col-span-6 box-data-FC mbs-3 line-height-1-2"
                        >
                          Contacto: {{ element.Contacto }}
                        </div>
                      </div>
                      <a href="javascript:;" @click="borrarHoja(element)">
                        <XIcon class="h-3 cross-delete mbs-3 line-height-1-2" />
                      </a>
                      <div class="grid grid-cols-12 mbs-3 line-height-1-2">
                        <p class="col-span-12 box-data">
                          Solicitante: {{ element.Solicitante }}
                        </p>
                        <p class="col-span-12 box-data mbs-3 line-height-1-2">
                          Avaluo: {{ element.Avaluo }}
                        </p>
                        <p class="col-span-12 box-data mbs-3 line-height-1-2">
                          Ubicacion: {{ element.Ubicacion }}
                        </p>
                        <p class="col-span-12 box-data mbs-3 line-height-1-2">
                          Folio Hacendario: {{ element.FolioHacendario }}
                        </p>
                        <p class="col-span-12 box-data mbs-3 line-height-1-2">
                          Escritura: {{ element.Escritura }}
                        </p>
                      </div>
                      <div class="grid grid-cols-12">
                        <div class="col-span-6 box-data mbs-3 line-height-1-2">
                          Expediente: {{ element.Expediente }}
                        </div>
                        <div class="col-span-6 box-data mbs-3 line-height-1-2">
                          Número de avalúos: {{ element.NumAvaluos }}
                        </div>
                      </div>

                      <div
                        class="
                          w-full
                          border-t border-gray-800
                          dark:border-dark-5
                          border-dashed
                        "
                      ></div>
                    </div>
                    <div class="box-data-FC">
                      Responsable: __________________________________
                    </div>
                  </div>
                  <button type="button" class="btn-style" @click="crearEntrega">
                    Imprimir y Guardar
                    <PrinterIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Super Large Slide Over Content -->
    <Toast />
    <!-- Begin: Modal Delete -->
    <div
      id="delete-modal-preview"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Estas Seguro?</div>
              <div class="text-gray-600 mt-2">
                Desea eliminar el id {{ id }} Contacto {{ Contacto }} ?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="
                  btn btn-outline-secondary
                  w-24
                  dark:border-dark-5 dark:text-gray-300
                  mr-1
                "
              >
                Cancel
              </button>
              <button
                data-dismiss="modal"
                type="button"
                class="btn btn-danger w-24"
                @click="eliminarEntrega"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRefs,
  computed
} from 'vue'
import feather from 'feather-icons'
import Tabulator from 'tabulator-tables'
import todoEntregas from '../../apiaccess/todoEntregas'
import todoSolicitudes from '../../apiaccess/todoSolicitudes'
import todoCatastro from '../../apiaccess/todoCatastro'
import todoLogs from '@/apiaccess/todoLogs'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { Printd } from 'printd'
export default defineComponent({
  setup() {
    /* Variables para imprimir */
    const cssText = `
                    .box-title {
                      margin-top: 5px;
                      margin-bottom: 5px;
                    }
                    .hoja {
                      background: #fff;
                      width: 90%;
                      height: 88%;
                      padding: 15px 35px;
                      margin: 0 auto;
                      position: relative;
                      margin-top: 60px;
                    }
                    h2, h3 {
                        font-size: 13px;
                        text-align: center;
                        margin-bottom: 3px;
                    }
                    .cross-delete{
                      display: none;
                    }
                    .box-data-FC{
                        font-size: 12px;
                        line-height: 50px;
                    }
                    .box-data{
                        font-size: 10px;
                        line-height: 11px;
                    }
                    .mbs-3{
                        margin-bottom: 3px;
                    }
                    .line-height-1-2{
                        line-height: 1.2;
                    }
                    `
    /* variables Hoja */
    const elements = ref([])
    const print = ref(null)
    /* Variables Api */
    const Solicitante = ref(null)
    const filteredSolicitante = ref(null)
    /* Variables Toast */
    const toast = useToast()
    /* variables para llamada api */
    const nuevo = ref(true)
    /* variables para token y store */
    const store = useStore()
    const token = ref(store.getters['firebase/GetToken'])
    /* variables para tabla */
    const tableRef = ref()
    const tabulator = ref()
    const filter = reactive({
      field: 'name',
      type: 'like',
      value: ''
    })
    // Formato Moneda
    const options = { style: 'currency', currency: 'MXN' }
    const formatMoneda = new Intl.NumberFormat('es-MX', options)
    /* variables para el slider */
    const idSolicitud = ref([])
    const slider = reactive({
      id: null,
      Folio: null,
      Fecha: new Date(new Date().toString().split('GMT')[0] + ' UTC')
        .toISOString()
        .split('T')[0],
      Cliente: null,
      TipoAvaluo: null,
      Avaluo: null,
      Solicitante: null,
      Contacto: null,
      Ubicacion: null,
      FolioHacendario: null,
      Escritura: null,
      Expediente: null,
      ValorAvaluo: null,
      DerCatastro: null,
      NumAvaluos: null
    })
    const type = ref([
      { name: 'UTILIZADO' },
      { name: 'VISOP' },
      { name: 'PRAH' }
    ])
    /* Funciones para el slider */
    const searchSolicitante = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredSolicitante.value = [...Solicitante.value]
          filteredSolicitante.value = filteredSolicitante.value.map(
            (filtro) => {
              if (filtro.Solicitantes.length > 0) {
                return {
                  Nombre: filtro.Solicitantes[0].Nombre,
                  RFC: filtro.Solicitantes[0].RFC,
                  CURP: filtro.Solicitantes[0].CURP,
                  Cliente: filtro.Cliente,
                  Contacto: filtro.Contacto,
                  id: filtro.id,
                  Folio: filtro.Folio,
                  Ubicacion: `${filtro.InmuebleCalle} ${filtro.InmuebleNumExt} ${filtro.InmuebleNumInt} ${filtro.InmuebleColonia} ${filtro.InmuebleEstado} ${filtro.InmuebleMunicipio} ${filtro.InmuebleCP}`,
                  FolioHacendario: filtro.FolioHacendario,
                  Escritura: filtro.Escritura,
                  Expediente: filtro.Expediente,
                  Der_Catastro: filtro.Der_Catastro
                }
              }
            }
          )
        } else {
          filteredSolicitante.value = Solicitante.value.filter((solicitud) => {
            solicitud.Folio = String(solicitud.Folio)
            return solicitud.Folio.startsWith(event.query)
          })
          filteredSolicitante.value = filteredSolicitante.value.map(
            (filtro) => {
              if (filtro.Solicitantes.length > 0) {
                return {
                  Nombre: filtro.Solicitantes[0].Nombre,
                  RFC: filtro.Solicitantes[0].RFC,
                  CURP: filtro.Solicitantes[0].CURP,
                  Cliente: filtro.Cliente,
                  Contacto: filtro.Contacto,
                  id: filtro.id,
                  Folio: filtro.Folio,
                  Ubicacion: `${filtro.InmuebleCalle} ${filtro.InmuebleNumExt} ${filtro.InmuebleNumInt} ${filtro.InmuebleColonia} ${filtro.InmuebleEstado} ${filtro.InmuebleMunicipio} ${filtro.InmuebleCP}`,
                  FolioHacendario: filtro.FolioHacendario,
                  Escritura: filtro.Escritura,
                  Expediente: filtro.Expediente,
                  Der_Catastro: filtro.Der_Catastro
                }
              }
            }
          )
        }
      }, 250)
    }
    const borrarForm = () => {
      slider.Folio = null
      slider.Cliente = null
      slider.Contacto = null
      slider.TipoAvaluo = null
      slider.Avaluo = null
      slider.Ubicacion = null
      slider.FolioHacendario = null
      slider.Escritura = null
      slider.Expediente = null
      slider.ValorAvaluo = null
      slider.DerCatastro = null
      slider.NumAvaluos = null
    }
    const SeleccionSolicitante = (event) => {
      idSolicitud.value.push(event.value.id)
      slider.Folio = event.value.Folio
      slider.Solicitante = event.value.Nombre
      slider.Cliente = event.value.Cliente
      slider.Contacto = event.value.Contacto
      slider.Avaluo =
        event.value.Folio + new Date().getFullYear().toLocaleString().substr(-2)
      slider.Ubicacion = event.value.Ubicacion
      slider.Escritura = event.value.Escritura
      slider.Expediente = event.value.Expediente
    }
    const agregarHoja = () => {
      // retirar valores null de la ubicacion
      slider.Ubicacion = slider.Ubicacion.replace('null', '')
      elements.value.push({ ...slider })
      borrarForm()
    }
    const borrarHoja = (nota) => {
      const idx = elements.value.indexOf(nota)
      if (idx > -1) {
        elements.value.splice(idx, 1)
      }
    }
    /* Funciones para la tabla */
    const getFacturas = (url, config, params) => {
      return new Promise(function (resolve, reject) {
        todoEntregas
          .get(token.value, params)
          .then((data) => {
            resolve(data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }

    const initTabulator = () => {
      tabulator.value = new Tabulator(tableRef.value, {
        ajaxURL: 'http://127.0.0.1:8000/',
        ajaxRequestFunc: getFacturas,
        ajaxFiltering: true,
        ajaxSorting: true,
        printAsHtml: true,
        printStyled: true,
        pagination: 'remote',
        paginationSize: 10,
        paginationSizeSelector: [10, 20, 30, 40],
        layout: 'fitColumns',
        responsiveLayout: 'collapse',
        placeholder: 'No matching records found',
        columns: [
          {
            formatter: 'responsiveCollapse',
            width: 40,
            minWidth: 30,
            hozAlign: 'center',
            resizable: false,
            headerSort: false
          },
          // For HTML table
          {
            title: 'Solicitud',
            minWidth: 100,
            responsive: 0,
            field: 'id',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true,
            formatter(cell) {
              const dato = (cell.getData().Avaluo).toString()
              const folio = dato.substring(0, dato.length - 2)
              return folio
            }
          },
          {
            title: 'Fecha',
            minWidth: 100,
            responsive: 0,
            field: 'Fecha',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true,
            headerSort: false
          },
          {
            title: 'Avaluo',
            minWidth: 120,
            responsive: 0,
            field: 'Avaluo',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true
          },
          {
            title: 'Cliente',
            minWidth: 120,
            responsive: 0,
            field: 'Cliente',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true
          },
          {
            title: 'Solicitante',
            minWidth: 120,
            responsive: 0,
            field: 'Solicitante',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true
          },
          {
            title: 'Ubicación',
            minWidth: 120,
            responsive: 0,
            field: 'Ubicacion',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true
          },
          {
            title: 'Escritura',
            minWidth: 120,
            responsive: 0,
            field: 'Escritura',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true
          },
          {
            title: 'Expediente',
            minWidth: 120,
            responsive: 0,
            field: 'Expediente',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true
          },
          {
            title: 'FolioHacendario',
            minWidth: 120,
            responsive: 0,
            field: 'FolioHacendario',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true
          },
          {
            title: 'ValorAvaluo',
            minWidth: 120,
            responsive: 0,
            field: 'ValorAvaluo',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true,
            formatter(cell) {
              const dato = cell.getData().ValorAvaluo
              return formatMoneda.format(dato)
            }
          },
          {
            title: 'Contacto',
            minWidth: 120,
            responsive: 0,
            field: 'Contacto',
            vertAlign: 'middle',
            hozAlign: 'center',
            print: true,
            download: true
          },
          {
            title: 'ACTIONS',
            minWidth: 246,
            field: 'actions',
            responsive: 0,
            hozAlign: 'center',
            vertAlign: 'middle',
            headerSort: false,
            print: false,
            download: false,
            columns: [
              {
                title: 'Editar',
                width: 80,
                field: 'Editar',
                hozAlign: 'center',
                headerSort: false,
                responsive: 0,
                print: false,
                download: false,
                formatter() {
                  return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#superlarge-slide-over-size-preview" class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i>
                </a>
                </div>`
                },
                cellClick: function (e, cell) {
                  nuevo.value = false
                  // Obtener Folio Avaluo
                  slider.Folio = cell.getData().Avaluo.slice(0, -2)
                  slider.id = cell.getData().id
                  slider.Solicitante = cell.getData().Solicitante
                  slider.Cliente = cell.getData().Cliente
                  slider.Contacto = cell.getData().Contacto
                  slider.TipoAvaluo = cell.getData().TipoAvaluo
                  slider.Avaluo = cell.getData().Avaluo
                  slider.Ubicacion = cell.getData().Ubicacion
                  slider.FolioHacendario = cell.getData().FolioHacendario
                  slider.Escritura = cell.getData().Escritura
                  slider.Expediente = cell.getData().Expediente
                  slider.ValorAvaluo = cell.getData().ValorAvaluo
                  slider.DerCatastro = cell.getData().DerCatastro
                  slider.NumAvaluos = cell.getData().NumAvaluos
                }
              },
              {
                title: 'Eliminar',
                width: 100,
                hozAlign: 'center',
                field: 'Eliminar',
                headerSort: false,
                responsive: 0,
                print: false,
                download: false,
                formatter() {
                  return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#delete-modal-preview" class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="trash-2" class="w-4 h-4 mr-1"></i>
                </a>
                </div>`
                },
                cellClick: function (e, cell) {
                  slider.id = cell.getData().id
                  slider.Contacto = cell.getData().Contacto
                }
              }
            ]
          }
        ],
        renderComplete() {
          feather.replace({
            'stroke-width': 1.5
          })
        }
      })
    }
    // Redraw table onresize
    const reInitOnResizeWindow = () => {
      window.addEventListener('resize', () => {
        tabulator.value.redraw()
        feather.replace({
          'stroke-width': 1.5
        })
      })
    }

    // Filter function
    const onFilter = () => {
      tabulator.value.setFilter(filter.field, filter.type, filter.value)
    }

    // On reset filter
    const onResetFilter = () => {
      filter.field = 'name'
      filter.type = 'like'
      filter.value = ''
      onFilter()
    }

    // Export
    const onExportCsv = () => {
      tabulator.value.download('csv', 'data.csv')
    }

    // Print
    const onPrint = () => {
      tabulator.value.print()
    }
    const crearEntrega = (e) => {
      e.preventDefault()
      elements.value.forEach(async (element, k) => {
        element.TipoAvaluo = element.TipoAvaluo.name
        const fecha = new Date(new Date().toString().split('GMT')[0] + ' UTC')
          .toISOString()
          .split('T')[0]
        try {
          const obj = {
            entrega: element,
            idSolicitud: idSolicitud.value[k],
            fecha: fecha
          }
          await todoEntregas.post(token.value, obj)
          // await todoSolicitudes.patch(
          //   token.value,
          //   {
          //     Estatus: 'Entregado',
          //     DiasParaEntregar: 0,
          //     FechaEntrega: fecha,
          //     FolioHacendario: element.FolioHacendario,
          //     ValorAvaluo: element.ValorAvaluo,
          //     Der_Catastro: element.DerCatastro
          //   },
          //   idSolicitud.value[k]
          // )
          initTabulator()
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: 'Se creo Correctamente el Valor',
            life: 3000
          })
        } catch (error) {
          const obj = {
            origen: 'Crear entrega y actualizar estatus solicitud',
            error: String(error) + JSON.stringify(error.response) + JSON.stringify(error.response.data),
            data: JSON.stringify(element) + String(idSolicitud.value[k])
          }
          await todoLogs.nuevoLog(token.value, obj)
          toast.add({
            severity: 'error',
            summary: 'error Message',
            detail: error.response,
            life: 3000
          })
        }
      })
      const d = new Printd()
      d.print(print.value, [cssText])
    }
    const editarEntrega = async (e) => {
      e.preventDefault()
      try {
        await todoEntregas.patch(token.value, slider, slider.id)
        initTabulator()
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Se creo Correctamente el Valor',
          life: 3000
        })
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response,
          life: 3000
        })
        console.error(error)
      }
    }
    /* Elimina Valor */
    const eliminarEntrega = async () => {
      try {
        await todoEntregas.delete(token.value, slider.id)
        toast.add({
          severity: 'success',
          summary: 'success Message',
          detail: 'Se elimino correctamente el valor',
          life: 3000
        })
        initTabulator()
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response.data,
          life: 3000
        })
      }
    }
    /* Identificar Valores en intervalos */
    const between = (x, min, max) => {
      return x > min && x <= max
    }
    /* Obtener valores de la tabla */
    const obtenerTablaValor = (umas) => {
      const tablaCatastro = store.getters['main/tablaCatastro']
      let reg = null
      if (tablaCatastro != null) {
        tablaCatastro.forEach((el) => {
          const intervalo = el.valor.split('-')
          // Valor inicial
          if (intervalo[0] == 0 && reg == null) {
            if (umas < intervalo[1]) {
              reg = el
            }
          }
          // Valor Final
          if (intervalo[1] == 0 && reg == null) {
            if (umas > intervalo[0]) {
              reg = el
            }
          } else {
            if (between(umas, intervalo[0], intervalo[1]) && reg == null) {
              reg = el
            }
          }
        })
      }
      return reg
    }
    /* Calcular Derechos de catastro */
    const derechosCatastro = (event) => {
      const uma = store.getters['main/uma']
      const valorAvaluo = parseFloat(event.target.value).toFixed(2)
      if (!isNaN(valorAvaluo)) {
        let umasAvaluo = 0
        let operacion3 = 0
        if (uma != null) {
          umasAvaluo = valorAvaluo / parseFloat(uma.anual).toFixed(2)
          const regTabla = obtenerTablaValor(umasAvaluo)
          if (regTabla != null) {
            const operacion1 =
              valorAvaluo * (parseFloat(regTabla.multiplicador) / 1000)
            const operacion2 =
              parseFloat(regTabla.uma).toFixed(2) *
              parseFloat(uma.diario).toFixed(2)
            operacion3 = (operacion1 + operacion2).toFixed(2)
          }
        }
        slider.DerCatastro = operacion3
      } else {
        slider.DerCatastro = 0
      }
    }
    /* Obtener datos de catastro de la base de datos */
    const obtenerDatosCatastro = () => {
      // Obtener umas para calculo de derechos
      todoCatastro
        .get(token.value)
        .then((result) => {
          store.dispatch('main/UMA', result.data[0])
        })
        .catch((error) => {
          console.error(error)
        })
      // Obtener tabla de catastro
      todoCatastro
        .getTabla(token.value)
        .then((result) => {
          store.dispatch('main/TABLACATASTRO', result.data)
        })
        .catch((error) => {
          console.error(error)
        })
    }
    /* Limpia los campos al abrir nuevo valor */
    const limpiarSlider = () => {
      // nuevo.value = true
      slider.id = null
      slider.Folio = null
      slider.Cliente = null
      slider.TipoAvaluo = null
      slider.Avaluo = null
      slider.Solicitante = null
      slider.Contacto = null
      slider.Ubicacion = null
      slider.FolioHacendario = null
      slider.Escritura = null
      slider.Expediente = null
      slider.ValorAvaluo = null
      slider.DerCatastro = null
      slider.NumAvaluos = null

      elements.value = []

      if (
        store.getters['main/uma'] == null &&
        store.getters['main/tablaCatastro'] == null
      ) {
        obtenerDatosCatastro()
      }
    }
    onMounted(() => {
      const infinito = {
        filters: [],
        page: 1,
        size: 10000,
        sorters: []
      }
      initTabulator()
      reInitOnResizeWindow()
      todoSolicitudes.get(token.value, infinito).then((data) => {
        Solicitante.value = data.data.data
        return Solicitante.value
      })
    })
    return {
      tableRef,
      filter,
      onFilter,
      onResetFilter,
      onExportCsv,
      onPrint,
      ...toRefs(slider),
      crearEntrega,
      derechosCatastro,
      limpiarSlider,
      eliminarEntrega,
      editarEntrega,
      nuevo,
      filteredSolicitante,
      elements,
      searchSolicitante,
      SeleccionSolicitante,
      type,
      agregarHoja,
      borrarHoja,
      print,
      idSolicitud,
      uma: computed(() => store.getters['main/uma']),
      tablaCatastro: computed(() => store.getters['main/tablaCatastro'])
    }
  }
})
</script>
