import Service from './Service'

const resourceuma = 'administrativo/uma/'
const resourcetabla = 'administrativo/tabladerechos/'

export default {
  get(token) {
    return Service.get(resourceuma, {
      headers: {
        authorization: `Token ${token}`
      }
    })
  },
  getTabla(token) {
    return Service.get(resourcetabla, {
      headers: {
        authorization: `Token ${token}`
      }
    })
  }
}
