import Service from './Service'

const resource = 'administrativo/solicitud/'

export default {
  get(token, params) {
    let parametros = ''
    if (params) {
      parametros = {
        page: params.page,
        size: params.size
      }
      if (params.filters.length > 0) {
        parametros = {
          page: params.page,
          size: params.size
        }
        parametros[`${params.filters[0].field}`] = params.filters[0].value
      }
      if (params.filters.length > 1) {
        parametros[`${params.filters[1].field}`] = params.filters[1].value
      }
      if (params.sorters.length > 0) {
        if (params.sorters[0].dir === 'asc') {
          parametros['ordering'] = `${params.sorters[0].field}` // eslint-disable-line
        } else {
          parametros['ordering'] = `-${params.sorters[0].field}` // eslint-disable-line
        }
      }
    }
    return Service.get(resource, {
      headers: {
        authorization: `Token ${token}`
      },
      params: parametros
    })
  },
  post(token, data) {
    return Service.post(resource, data, {
      headers: {
        Authorization: `Token ${token}`
      }
    })
  },
  patch(token, data, id) {
    return Service.patch(resource + id + '/', data, {
      headers: {
        Authorization: `Token ${token}`
      }
    })
  },
  delete(token, id) {
    return Service.delete(resource + id + '/', {
      headers: {
        Authorization: `Token ${token}`
      }
    })
  }
}
