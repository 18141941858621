import * as types from './mutation-types'

const state = () => {
  return {
    darkMode: false,
    tablaCatastro: null,
    uma: null
  }
}

// getters
const getters = {
  darkMode: state => state.darkMode,
  tablaCatastro: state => state.tablaCatastro,
  uma: state => state.uma
}

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode })
  },
  TABLACATASTRO({ commit }, obj) {
    commit('SET_TABLACATASTRO', obj)
  },
  UMA({ commit }, obj) {
    commit('SET_UMA', obj)
  }
}

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode
  },
  SET_TABLACATASTRO(state, obj) {
    state.tablaCatastro = obj
  },
  SET_UMA(state, obj) {
    state.uma = obj
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
