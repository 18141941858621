<template>
  <div>
    <div class="intro-y flex flex-col sm:flex-row items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Solicitud</h2>
      <div class="w-full sm:w-auto flex mt-4 sm:mt-0">
        <a
          href="javascript:;"
          data-toggle="modal"
          data-target="#superlarge-slide-over-size-preview"
          class="btn btn-primary mr-1 mb-2"
          @click="limpiarSlider"
          >Nueva Solicitud</a
        >
      </div>
    </div>
    <!-- BEGIN: HTML Table Data -->
    <tabla ref="tableComponent" :column="column" :apiCall="apiCall"> </tabla>
    <!-- END: HTML Table Data -->
    <!-- BEGIN: Super Large Slide Over Content -->
    <div
      id="superlarge-slide-over-size-preview"
      class="modal modal-slide-over"
      data-backdrop="static"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <XIcon data-dismiss="modal" class="text-theme-6 cursor-pointer" />
            <h2 class="font-medium text-base mr-auto ml-5">Nuevo Registro</h2>
          </div>
          <div class="modal-body">
            <form class="container">
              <div class="grid grid-cols-12 p-2 gap-2">
                <label class="col-span-4">
                  Cliente
                  <AutoComplete
                    v-model="Cliente"
                    :suggestions="filteredCliente"
                    :dropdown="true"
                    @complete="searchCliente($event)"
                    @item-select="SeleccionCliente($event)"
                    field="Nombre"
                    style="width: 100%"
                    inputStyle="width: 100%"
                    placeholder="Cliente"
                  />
                </label>
                <label class="col-span-4">
                  Nombre Contacto
                  <AutoComplete
                    v-model="Contacto"
                    :suggestions="filteredContacto"
                    :dropdown="true"
                    field="Nombre"
                    @complete="searchContacto($event)"
                    @item-select="SeleccionContacto($event)"
                    style="width: 100%"
                    inputStyle="width: 100%"
                    placeholder="Contacto"
                  />
                </label>
                <label class="col-span-4">
                  Contacto Telefono
                  <input
                    v-model="ContactoTelefono"
                    type="text"
                    class="form-control"
                    placeholder="Telefono"
                    aria-label="Telefono Contacto"
                  />
                </label>
                <label class="col-span-4">
                  Contacto Iniciales
                  <input
                    v-model="ContactoIniciales"
                    type="text"
                    class="form-control col-span-2"
                    placeholder="Iniciales"
                    aria-label="Iniciales"
                  />
                </label>
              </div>
              <div class="grid grid-cols-12 p-2 gap-2">
                <div class="col-span-12 font-medium text-lg">
                  Solicitante
                  <a href="javascript:;" @click="agregarNuevo">
                    <PlusIcon />
                  </a>
                </div>
                <div
                  v-for="(Solicitante, k) in Solicitantes"
                  :key="k"
                  class="col-span-12"
                >
                  <a href="javascript:;" @click="deleteContact(k, Solicitante)">
                    <XIcon />
                  </a>
                  <div class="grid grid-cols-12 gap-2 p-2 ui-fluid">
                    <label class="col-span-4">
                      Solicitante Nombre
                      <AutoComplete
                        v-model="Solicitante.Nombre"
                        :suggestions="filteredSolicitante"
                        :dropdown="true"
                        field="Nombre"
                        @complete="searchSolicitante($event)"
                        @item-select="SeleccionSolicitante($event, k)"
                        style="width: 100%"
                        inputStyle="width: 100%"
                        placeholder="Solicitante"
                      />
                    </label>
                    <label class="col-span-4">
                      Solicitante CURP
                      <input
                        v-model="Solicitante.CURP"
                        type="text"
                        class="form-control col-span-4"
                        placeholder="CURP"
                        aria-label="CURP"
                      />
                    </label>
                    <label class="col-span-4">
                      Solicitante RFC
                      <input
                        v-model="Solicitante.RFC"
                        type="text"
                        class="form-control col-span-4"
                        placeholder="RFC"
                        aria-label="RFC"
                      />
                    </label>
                  </div>
                </div>
                <label class="col-span-4">
                  Clave Catastral
                  <AutoComplete
                    v-model="InmuebleClave"
                    :suggestions="filteredInmueble"
                    :dropdown="true"
                    field="Clave"
                    @complete="searchInmueble($event)"
                    @item-select="SeleccionInmueble($event)"
                    style="width: 100%"
                    inputStyle="width: 100%"
                    placeholder="Clave Catastral"
                  />
                </label>
                <label class="col-span-4">
                  Calle
                  <input
                    v-model="InmuebleCalle"
                    type="text"
                    class="form-control col-span-4"
                    placeholder="Calle"
                    aria-label="Calle Inmueble"
                  />
                </label>
                <label class="col-span-4">
                  Número Exterior
                  <input
                    v-model="InmuebleNumExt"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Exterior"
                    aria-label="Número Exterior"
                  />
                </label>
                <label class="col-span-4">
                  Número Interior
                  <input
                    v-model="InmuebleNumInt"
                    type="text"
                    class="form-control col-span-2"
                    placeholder="Interior"
                    aria-label="Número Interior"
                  />
                </label>
                <label for="" class="col-span-4">
                  Colonia
                  <input
                    v-model="InmuebleColonia"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Colonia"
                    aria-label="Colonia Inmueble"
                  />
                </label>
                <label for="" class="col-span-4">
                  Municipio
                  <input
                    v-model="InmuebleMunicipio"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Municipio"
                    aria-label="Municipio Inmueble"
                  />
                </label>
                <label for="" class="col-span-4">
                  Estado
                  <input
                    v-model="InmuebleEstado"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="Estado"
                    aria-label="Estado Inmueble"
                  />
                </label>
                <label for="" class="col-span-4">
                  Inmueble CP
                  <input
                    v-model="InmuebleCP"
                    type="text"
                    class="form-control col-span-3"
                    placeholder="CP"
                    aria-label="CP Inmueble"
                  />
                </label>
                <label for="" class="col-span-4">
                  Expediente
                  <input
                    v-model="Expediente"
                    type="text"
                    class="form-control"
                    placeholder="Expediente"
                    aria-label="Expediente"
                  />
                </label>
                <label for="" class="col-span-4">
                  Tipo Terreno
                  <Dropdown
                    class="form-select"
                    v-model="InmuebleTipo"
                    :options="type"
                    optionLabel="name"
                    :editable="true"
                  />
                </label>
                <label for="" class="col-span-4">
                  Tipo Solicitud
                  <Dropdown
                    class="form-select"
                    v-model="TipoSolicitud"
                    :options="typeSolicitud"
                    optionLabel="name"
                    :editable="true"
                  />
                </label>
                <label for="" class="col-span-4">
                  Equipo
                  <AutoComplete
                    v-model="Equipo"
                    :suggestions="filteredEquipo"
                    :dropdown="true"
                    field="Equipo"
                    @complete="searchEquipo($event)"
                    @item-select="SeleccionEquipo($event)"
                    style="width: 100%"
                    inputStyle="width: 100%"
                    class="form-control"
                    placeholder="Equipo"
                  />
                </label>
                <label for="" class="col-span-12 p-fluid">
                  Auxiliares
                  <AutoComplete
                    v-model="Auxiliares"
                    :suggestions="filteredAuxiliares"
                    :multiple="true"
                    :dropdown="true"
                    field="Nombre"
                    @complete="searchAuxiliares($event)"
                    @keydown.enter="SeleccionarEscrito($event)"
                    style="width: 100%"
                    inputStyle="width: 100%"
                    class="form-control"
                    placeholder="Auxiliares"
                  />
                </label>
                <label for="" class="col-span-4">
                  Escritura
                  <input
                    v-model="Escritura"
                    type="text"
                    class="form-control"
                    placeholder="Escritura"
                    aria-label="Escritura"
                  />
                </label>
                <label for="" class="col-span-4">
                  Fecha de creación
                  <input
                    v-model="Fecha"
                    class="form-control"
                    type="date"
                    @change="changeFecha"
                  />
                </label>
                <label for="" class="col-span-4">
                  Fecha Entrega
                  <input
                    v-model="FechaEntrega"
                    class="form-control"
                    type="date"
                  />
                </label>
                <label for="" class="col-span-4">
                  Dias para Entregar
                  <input
                    v-model="DiasParaEntregar"
                    type="text"
                    class="form-control"
                    placeholder="Dias Entrega"
                    aria-label="Dias Entrega"
                    disabled
                  />
                </label>
              </div>
              <input
                v-if="nuevo"
                data-dismiss="modal"
                class="w-full h-10 px-5 bg-success"
                type="submit"
                value="Enviar"
                @click="crearSolicitud"
              />
              <input
                v-else
                data-dismiss="modal"
                class="w-full h-10 px-5 bg-success"
                type="submit"
                value="Editar"
                @click="editarSolicitud"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Super Large Slide Over Content -->
    <Toast />
    <!-- Begin: Modal Delete -->
    <div
      id="delete-modal-preview"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-6 mx-auto mt-3" />
              <div class="text-3xl mt-5">Estas Seguro?</div>
              <div class="text-gray-600 mt-2">
                Desea eliminar el id {{ Folio }} Cliente {{ Cliente }} ?
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="
                  btn btn-outline-secondary
                  w-24
                  dark:border-dark-5 dark:text-gray-300
                  mr-1
                "
              >
                Cancel
              </button>
              <button
                data-dismiss="modal"
                type="button"
                class="btn btn-danger w-24"
                @click="eliminarSolicitud"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- BEGIN: Super Large Slide Over Content -->
    <div
      id="Print-slide-over-size-preview"
      class="modal modal-slide-over"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header p-5">
            <h2 class="font-medium text-base mr-auto">Etiqueta</h2>
          </div>
          <div class="modal-body">
            <div class="grid grid-cols-12 gap-2 p-2">
              <div class="col-span-12">
                <div class="grid grid-cols-6 gap-2 chat">
                  <div class="col-span-6">
                    <div id="Alta" aria-labelledby="Alta-tab" class="tab-pane">
                      <div class="box-form h-4/5">
                        <h3>Previsualización de Etiqueta</h3>
                        <p>
                          Aquí podrás verificar los datos a imprimir en la
                          etiqueta.
                        </p>
                        <b>
                          NOTA: Si se requiere cambiar información se deberá
                          hacer desde la tabla de solicitudes
                        </b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-span-12">
                <div class="box-preview">
                  <div ref="print" class="hoja">
                    <div class="grid grid-cols-12 gap-2">
                      <div
                        class="
                          border-style
                          generales
                          font-medium
                          text-lg
                          col-span-6
                          border-solid border-black border-2
                          mt-3
                        "
                      >
                        <div class="box-title">
                          <h2 class="color">Datos Generales</h2>
                        </div>
                        <div class="grid grid-cols-12 gap-2">
                          <div class="col-span-6 box-data-FC">
                            <div class="text">
                              <b class="subtitle">Fecha:</b>
                              <span>{{ Fecha }}</span>
                            </div>
                          </div>
                          <div class="col-span-6 box-data-FC">
                            <div class="text">
                              <b class="subtitle">Escritura:</b>
                              <span>{{ Escritura }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="grid grid-cols-12 gap-2">
                          <div class="col-span-6 box-data-FC">
                            <div class="text">
                              <b class="subtitle">Contacto:</b>
                              <span>{{ Contacto }}</span>
                            </div>
                          </div>
                          <div class="col-span-6 box-data-FC">
                            <div class="text">
                              <b class="subtitle">Expediente:</b>
                              <span>{{ Expediente }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="grid grid-cols-12">
                          <div class="col-span-6 box-data-FC">
                            <div class="text">
                              <b class="subtitle">Tipo de Solicitud:</b>
                              <span>{{ TipoSolicitud }}</span>
                            </div>
                          </div>
                          <div class="col-span-6 box-data-FC">
                            <div class="text">
                              <b class="subtitle">Clave Catastral:</b>
                              <span>{{ InmuebleClave }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="grid grid-cols-12">
                          <div class="col-span-6 box-data-FC">
                            <div class="text">
                              <b class="subtitle">Cliente:</b>
                              <span>{{ Cliente }}</span>
                            </div>
                          </div>
                          <div class="col-span-6 box-data-FC">
                            <div class="text">
                              <b class="subtitle">Solicitante:</b>
                              <span v-for="sol in Solicitantes" :key="sol.id">{{ sol.Nombre }}</span>
                            </div>
                          </div>
                          <div class="col-span-6 box-data-FC">
                            <div class="text">
                              <b class="subtitle">Fecha Entrega:</b>
                              <span>{{ FechaEntrega }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="font-medium text-lg col-span-6">
                        <div class="grid drid-cols-12 gap-2 p-2">
                          <div
                            class="
                              border-style
                              box-number
                              col-span-6
                              border-solid border-black border-2
                              mt-1
                              p-8
                            "
                          >
                            <div class="">
                              <h2 class="text-center number">{{ Folio }}</h2>
                              <p class="text-center">
                                {{ Cliente }}
                              </p>
                            </div>
                          </div>

                          <div
                            class="
                              border-style
                              box-ubicacion
                              col-span-6
                              border-solid border-black border-2
                              mt-2
                            "
                          >
                            <h2 class="text-center subtitle">Ubicación</h2>
                            <p class="text-center">
                              {{ InmuebleCalle }}
                            </p>
                          </div>
                          <div
                            class="
                              border-style
                              box-juzgado
                              col-span-6
                              border-solid border-black border-2
                              mt-2
                            "
                          >
                            <h2 class="text-center subtitle">Datos Juzgado</h2>
                            <div class="grid grid-cols-12">
                              <div class="col-span-12">
                                <div class="text">
                                  <b class="subtitle">Inmueble:</b>
                                </div>
                              </div>
                            </div>
                            <div class="grid grid-cols-12">
                              <div class="col-span-6">
                                <div class="text">
                                  <b class="subtitle">Juzgado:</b>
                                </div>
                              </div>
                              <div class="col-span-6">
                                <div class="text">
                                  <b class="subtitle">N° Expediente:</b>
                                </div>
                              </div>
                              <div class="col-span-6">
                                <div class="text">
                                  <b class="subtitle">Fecha Recepción:</b>
                                </div>
                              </div>
                              <div class="col-span-6">
                                <div class="text">
                                  <b class="subtitle">Actor:</b>
                                </div>
                              </div>
                              <div class="col-span-6">
                                <div class="text">
                                  <b class="subtitle">Demandado:</b>
                                </div>
                              </div>
                              <div class="col-span-6">
                                <div class="text">
                                  <b class="subtitle">Solicitante:</b>
                                </div>
                              </div>
                              <div class="col-span-6">
                                <div class="text">
                                  <b class="subtitle">Caracter:</b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <button type="button" class="btn-style" @click="ImprimirHoja">
                    Imprimir y Guardar
                    <PrinterIcon />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Super Large Slide Over Content -->
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  toRefs,
  computed
} from 'vue'
import todoSolicitudes from '../../apiaccess/todoSolicitudes'
import todoClientes from '../../apiaccess/todoClientes'
import todoInmuebles from '../../apiaccess/todoInmuebles'
import todoEquipos from '../../apiaccess/todoEquipos'
import todoSolicitantes from '../../apiaccess/todoSolicitantes'
import tabla from '@/global-components/tablaGeneral/Main.vue'
import { useStore } from 'vuex'
import { useToast } from 'primevue/usetoast'
import { Printd } from 'printd'
export default defineComponent({
  name: 'Solicitud',
  setup() {
    /* variable para imprimir */
    const print = ref(null)
    const cssText = `
                    .box-title {
                      margin-top: 10px;
                      margin-bottom: 10px;
                    }
                    .hoja {
                      background: #fff;
                      width: 90%;
                      height: 88%;
                      padding: 15px 35px;
                      margin: 0 auto;
                      position: relative;
                      margin-top: 60px;
                      transform: translateY(-15%);
                    }
                    h2, h3 {
                        font-size: 15px;
                        text-align: center;
                        margin-bottom: 3px;
                    }
                    .cross-delete{
                      display: none;
                    }
                    .box-data-FC{
                        font-size: 15px;
                        line-height: 50px;
                    }
                    .box-data{
                        font-size: 15px;
                        line-height: 11px;
                    }
                    .grid-cols-12{
                      display: flex;
                    }
                    .col-span-6{
                      grid-column: span 6 / span 6;
                    }
                    .p-2 {
                      padding: 0.5rem;
                    }
                    .grid-cols-12 {
                      grid-template-columns: repeat(12, minmax(0, 1fr));
                    }
                    .grid {
                      display: grid;
                    }

                    .border-style {
                      border-radius: 7px;
                      padding: 13px;
                    }
                    .border-black {
                      border: 1px solid black;
                    }
                    .subtitle {
                      font-size: 15px;
                      font-weight: bold;
                    }

                    .generales {
                      height: 400px;
                    }
                    .text {
                      display: grid;
                      line-height: 21px;
                    }
                    .text span {
                      font-size: 12px;
                      font-weight: 300;
                      margin-bottom: 15px;
                    }




                    .box-number {
                      height: 110px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      margin-top: -10px;
                      margin-bottom: 7px;
                    }
                    .number {
                      font-size: 75px;
                      font-weight: bold;
                      margin-bottom: 35px;
                      line-height: 7px;
                    }
                    .box-number p {
                      font-size: 17px;
                      font-weight: 300;
                    }
                    .box-ubicacion {
                      height: 90px;
                      margin-bottom: 7px;
                    }
                    .box-ubicacion p {
                      font-size: 11px;
                      font-weight: 200;
                    }

                    .box-juzgado {
                      height: 130px;
                    }
                    .box-juzgado p {
                      font-size: 12px;
                      font-weight: 300;
                    }



                    `
    /* variable para toast */
    const toast = useToast()
    /* variables para llamada api */
    const nuevo = ref(true)
    const Clientes = ref()
    const Inmuebles = ref()
    const Equipos = ref()
    const AuxiliaresAPI = ref()
    const ContactosAPI = ref()
    const Solicitantes = ref()
    /* variables para token y store */
    const store = useStore()
    const token = ref(store.getters['firebase/GetToken'])
    /* variables para tabla */
    const tableComponent = ref()
    const column = [
      {
        formatter: 'responsiveCollapse',
        width: 40,
        minWidth: 30,
        hozAlign: 'center',
        resizable: false,
        headerSort: false
      },
      // For HTML table
      {
        title: 'Folio',
        minWidth: 80,
        responsive: 0,
        field: 'Folio',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Fecha',
        minWidth: 80,
        responsive: 0,
        field: 'Fecha',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true,
        headerSort: false
      },
      {
        title: 'Cliente',
        minWidth: 80,
        responsive: 0,
        field: 'Cliente',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Solicitante',
        minWidth: 80,
        responsive: 0,
        field: 'Solicitantes',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true,
        formatter(cell) {
          if (cell.getData().Solicitantes[0]) {
            const solicitante = cell.getData().Solicitantes[0]
            return solicitante.Nombre
          }
        }
      },
      {
        title: 'Calle',
        minWidth: 80,
        responsive: 0,
        field: 'InmuebleCalle',
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: false,
        download: true,
        formatter(cell) {
          return `
                <input type="text" class="text-center w-full font-12" value="Calle: ${
                  cell.getData().InmuebleCalle
                } Num: # ${cell.getData().InmuebleNumExt} Int: # ${
            cell.getData().InmuebleNumInt
          } Col. ${cell.getData().InmuebleColonia} Estado: ${
            cell.getData().InmuebleEstado
          } Municipio: ${cell.getData().InmuebleMunicipio} C.P ${
            cell.getData().InmuebleCP
          }" disabled>`
        }
      },
      {
        title: 'Calle',
        minWidth: 400,
        responsive: 0,
        field: 'InmuebleCalle',
        visible: false,
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: true,
        download: true,
        formatter(cell) {
          return `
                <input type="text" class="text-center w-full font-12" value="Calle: ${
                  cell.getData().InmuebleCalle
                } Num: # ${cell.getData().InmuebleNumExt} Int: # ${
            cell.getData().InmuebleNumInt
          } Col. ${cell.getData().InmuebleColonia} Estado: ${
            cell.getData().InmuebleEstado
          } Municipio: ${cell.getData().InmuebleMunicipio} C.P ${
            cell.getData().InmuebleCP
          }" disabled>`
        }
      },
      {
        title: 'Escritura',
        minWidth: 80,
        responsive: 0,
        field: 'Escritura',
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: true,
        download: true
      },
      {
        title: 'Expediente',
        minWidth: 80,
        responsive: 0,
        field: 'Expediente',
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: true,
        download: true
      },
      {
        title: 'Factura',
        minWidth: 80,
        responsive: 0,
        field: 'Factura',
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: true,
        download: true
      },
      {
        title: 'Clave Catastral',
        minWidth: 80,
        responsive: 0,
        field: 'InmuebleClave',
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: true,
        download: true
      },
      {
        title: 'Folio Hacendario',
        minWidth: 80,
        responsive: 0,
        field: 'FolioHacendario',
        vertAlign: 'middle',
        hozAlign: 'center',
        headerSort: false,
        print: true,
        download: true
      },
      {
        title: 'Entrega',
        minWidth: 80,
        responsive: 0,
        field: 'FechaEntrega',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Disp',
        minWidth: 80,
        responsive: 0,
        field: 'DiasParaEntregar',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'Status',
        minWidth: 80,
        responsive: 0,
        field: 'Estatus',
        vertAlign: 'middle',
        hozAlign: 'center',
        print: true,
        download: true
      },
      {
        title: 'ACTIONS',
        minWidth: 246,
        field: 'actions',
        responsive: 0,
        hozAlign: 'center',
        vertAlign: 'middle',
        headerSort: false,
        print: false,
        download: false,
        columns: [
          {
            title: 'Editar',
            width: 80,
            field: 'Editar',
            hozAlign: 'center',
            headerSort: false,
            responsive: 0,
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#superlarge-slide-over-size-preview" class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="check-square" class="w-4 h-4 mr-1"></i>
                </a>
                </div>`
            },
            cellClick: function (e, cell) {
              nuevo.value = false
              slider.id = cell.getData().id
              slider.Folio = cell.getData().Folio
              slider.Anio = cell.getData().Anio
              slider.Cliente = cell.getData().Cliente
              slider.Contacto = cell.getData().Contacto
              slider.ContactoTelefono = cell.getData().ContactoTelefono
              slider.ContactoIniciales = cell.getData().ContactoIniciales
              if (cell.getData().Solicitantes.length == 0) {
                slider.Solicitantes = [
                  {
                    Nombre: null,
                    CURP: null,
                    RFC: null
                  }
                ]
              } else {
                slider.Solicitantes = cell.getData().Solicitantes
              }
              slider.InmuebleClave = cell.getData().InmuebleClave
              slider.InmuebleTipo = cell.getData().InmuebleTipo
              slider.InmuebleCalle = cell.getData().InmuebleCalle
              slider.InmuebleNumExt = cell.getData().InmuebleNumExt
              slider.InmuebleNumInt = cell.getData().InmuebleNumInt
              slider.InmuebleColonia = cell.getData().InmuebleColonia
              slider.InmuebleMunicipio = cell.getData().InmuebleMunicipio
              slider.InmuebleEstado = cell.getData().InmuebleEstado
              slider.InmuebleCP = cell.getData().InmuebleCP
              slider.Expediente = cell.getData().Expediente
              slider.TipoSolicitud = cell.getData().TipoSolicitud
              slider.Equipo = cell.getData().Equipo
              slider.Auxiliares = cell.getData().Auxiliares
              slider.Estatus = cell.getData().Estatus
              slider.Escritura = cell.getData().Escritura
              slider.Fecha = cell.getData().Fecha
              slider.FechaEntrega = cell.getData().FechaEntrega
            }
          },
          {
            title: 'Eliminar',
            width: 100,
            hozAlign: 'center',
            field: 'Eliminar',
            headerSort: false,
            responsive: 0,
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#delete-modal-preview" class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="trash-2" class="w-4 h-4 mr-1"></i>
                </a>
                </div>`
            },
            cellClick: function (e, cell) {
              slider.id = cell.getData().id
              slider.Folio = cell.getData().Folio
              slider.Cliente = cell.getData().Cliente
            }
          },
          {
            title: 'Etiqueta',
            width: 100,
            hozAlign: 'center',
            field: 'etiqueta',
            headerSort: false,
            responsive: 0,
            print: false,
            download: false,
            formatter() {
              return `<div class="flex lg:justify-center items-center">
                <a data-toggle="modal" data-target="#Print-slide-over-size-preview" class="flex items-center mr-3" href="javascript:;">
                  <i data-feather="printer" class="w-4 h-4 mr-1"></i>
                </a>
                </div>`
            },
            cellClick: function (e, cell) {
              slider.id = cell.getData().id
              slider.Folio = cell.getData().Folio
              slider.Cliente = cell.getData().Cliente
              slider.TipoSolicitud = cell.getData().TipoSolicitud
              slider.InmuebleClave = cell.getData().InmuebleClave
              slider.Solicitantes = cell.getData().Solicitantes
              slider.Contacto = cell.getData().Contacto
              slider.Expediente = cell.getData().Expediente
              slider.Escritura = cell.getData().Escritura
              slider.FechaEntrega = cell.getData().FechaEntrega
              const cp = (cell.getData().InmuebleCP != null) ? 'C.P' + cell.getData().InmuebleCP : ''
              const numExt = (cell.getData().InmuebleNumExt != null) ? cell.getData().InmuebleNumExt : ''
              const numInt = (cell.getData().InmuebleNumInt != null) ? cell.getData().InmuebleNumInt : ''
              const ubicacion = `${
                cell.getData().InmuebleCalle
              } ${numExt} ${
                numInt
              } Col. ${cell.getData().InmuebleColonia} 
                  ${cell.getData().InmuebleEstado} Municipio: ${
                cell.getData().InmuebleMunicipio
              } ${cp}`
              slider.InmuebleCalle = ubicacion
            }
          }
        ]
      }
    ]
    const apiCall = (url, config, params) => {
      return new Promise(function (resolve, reject) {
        todoSolicitudes
          .get(token.value, params)
          .then((data) => {
            resolve(data.data)
          })
          .catch((error) => {
            reject(error)
          })
      })
    }
    /* variables para el slider */
    const slider = reactive({
      id: null,
      Folio: null,
      Anio: new Date().getFullYear(),
      Fecha: new Date(new Date().toString().split('GMT')[0] + ' UTC')
        .toISOString()
        .split('T')[0],
      Cliente: null,
      Contacto: null,
      ContactoTelefono: null,
      ContactoIniciales: null,
      Solicitantes: [
        {
          Nombre: null,
          CURP: null,
          RFC: null
        }
      ],
      InmuebleClave: null,
      InmuebleTipo: null,
      InmuebleCalle: null,
      InmuebleNumExt: null,
      InmuebleNumInt: null,
      InmuebleColonia: null,
      InmuebleMunicipio: null,
      InmuebleEstado: null,
      InmuebleCP: null,
      Expediente: null,
      TipoSolicitud: '',
      Equipo: null,
      Auxiliares: [],
      FechaEntrega: '',
      DiasParaEntregar: computed(() => {
        const fechaFin = new Date(slider.FechaEntrega)
        let fechaInicio = new Date(slider.Fecha)
        const dias = (fechaFin - fechaInicio) / (1000 * 60 * 60 * 24) + 1
        let semanas = 0
        for (let index = 0; index < dias; index++) {
          if (fechaInicio.getDay() == 5 || fechaInicio.getDay() == 6) semanas++
          fechaInicio = fechaInicio.valueOf()
          fechaInicio += 1000 * 60 * 60 * 24
          fechaInicio = new Date(fechaInicio)
        }
        return dias - semanas
      }),
      Estatus: 'Pendiente',
      Escritura: null
    })
    const changeFecha = () => {
      const diaActual = new Date(slider.Fecha)
      if (slider.TipoSolicitud.name) {
        if (slider.TipoSolicitud.name.includes('Hacienda')) {
          fechaEntregaHacendario(diaActual)
        } else {
          fechaEntregaOtros(diaActual)
        }
      } else {
        if (slider.TipoSolicitud === '' || slider.TipoSolicitud === null) {
          fechaEntregaOtros(diaActual)
        } else if (slider.TipoSolicitud.includes('Hacienda')) {
          fechaEntregaHacendario(diaActual)
        } else {
          fechaEntregaOtros(diaActual)
        }
      }
    }
    const filteredCliente = ref(null)
    const filteredInmueble = ref(null)
    const filteredEquipo = ref(null)
    const filteredAuxiliares = ref(null)
    const filteredContacto = ref(null)
    const filteredSolicitante = ref(null)
    const type = ref([{ name: 'Terreno' }, { name: 'Casa Habitacion' }])
    const typeSolicitud = ref([
      { name: 'Avalúo Hacendario' },
      { name: 'Avalúo Hacendario Personal' },
      { name: 'Avalúo Comercial Personal' },
      { name: 'Avalúo Comercial Infonavit' },
      { name: 'Avalúo Comercial Fovisste' },
      { name: 'Avalúo Comercial Juicio' },
      { name: 'Avalúo Condominio' },
      { name: 'Avalúo Condominio y Memoria Descriptiva' }
    ])
    /* Funciones para el slider */
    const fechaEntregaHacendario = (diaActual) => {
      let fechaE = null
      try {
        if (
          diaActual.getDay() === 0 ||
          diaActual.getDay() === 1 ||
          diaActual.getDay() === 2
        ) {
          fechaE = new Date(
            diaActual.getFullYear(),
            diaActual.getMonth(),
            diaActual.getDate() + 3
          )
        } else if (diaActual.getDay() === 3 || diaActual.getDay() === 4) {
          fechaE = new Date(
            diaActual.getFullYear(),
            diaActual.getMonth(),
            diaActual.getDate() + 5
          )
        }
        slider.FechaEntrega = fechaE.toISOString().split('T')[0]
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'Error Message',
          detail: 'No se permite Seleccionar Sabado o Domingo',
          life: 3000
        })
      }
    }
    const fechaEntregaOtros = (diaActual) => {
      let fechaE = null
      if (diaActual.getDay() === 0) {
        fechaE = new Date(
          diaActual.getFullYear(),
          diaActual.getMonth(),
          diaActual.getDate() + 5
        )
      } else if (
        diaActual.getDay() === 1 ||
        diaActual.getDay() === 2 ||
        diaActual.getDay() === 3 ||
        diaActual.getDay() === 4 ||
        diaActual.getDay() === 5
      ) {
        fechaE = new Date(
          diaActual.getFullYear(),
          diaActual.getMonth(),
          diaActual.getDate() + 7
        )
      } else if (diaActual.getDay() === 6) {
        fechaE = new Date(
          diaActual.getFullYear(),
          diaActual.getMonth(),
          diaActual.getDate() + 6
        )
      }
      slider.FechaEntrega = fechaE.toISOString().split('T')[0]
    }
    const agregarNuevo = () => {
      slider.Solicitantes.push({
        Nombre: null,
        CURP: null,
        RFC: null
      })
    }
    const deleteContact = (index, Solicitante) => {
      const idx = slider.Solicitantes.indexOf(Solicitante)
      if (idx > -1) {
        slider.Solicitantes.splice(idx, 1)
      }
    }
    const searchCliente = (event) => {
      if (!event.query.trim().length) {
        filteredCliente.value = [...Clientes.value]
      } else {
        filteredCliente.value = Clientes.value.filter((inmueble) => {
          return inmueble.Nombre.toLowerCase().startsWith(
            event.query.toLowerCase()
          )
        })
      }
    }
    const searchInmueble = (event) => {
      if (!event.query.trim().length) {
        filteredInmueble.value = [...Inmuebles.value]
        filteredInmueble.value = Inmuebles.value.filter((inmueble) => {
          // Validar si existe la clave catastral en el inmueble
          if (inmueble.Clave != null) {
            return inmueble.Clave.toLowerCase().startsWith(
              event.query.toLowerCase()
            )
          } else {
            return ''
          }
        })
      } else {
        filteredInmueble.value = Inmuebles.value.filter((inmueble) => {
          // Validar si existe la clave catastral en el inmueble
          if (inmueble.Clave != null) {
            return inmueble.Clave.toLowerCase().startsWith(
              event.query.toLowerCase()
            )
          } else {
            return ''
          }
        })
      }
    }
    const searchEquipo = (event) => {
      if (!event.query.trim().length) {
        filteredEquipo.value = [...Equipos.value]
      } else {
        filteredEquipo.value = Equipos.value.filter((equipo) => {
          return equipo.Equipo.toLowerCase().startsWith(
            event.query.toLowerCase()
          )
        })
      }
    }
    const searchAuxiliares = (event) => {
      if (slider.Equipo === null || slider.Equipo === '') {
        toast.add({
          severity: 'info',
          summary: 'Info Message',
          detail: 'Seleccione un Equipo',
          life: 3000
        })
        filteredAuxiliares.value = []
      } else {
        if (!event.query.trim().length) {
          filteredAuxiliares.value = [...AuxiliaresAPI.value]
        } else {
          filteredAuxiliares.value = AuxiliaresAPI.value.filter((equipo) => {
            return equipo.name
              .toLowerCase()
              .startsWith(event.query.toLowerCase())
          })
        }
      }
    }
    const searchContacto = (event) => {
      if (slider.Cliente === null || slider.Cliente === '') {
        toast.add({
          severity: 'info',
          summary: 'Info Message',
          detail: 'Seleccione un Cliente',
          life: 3000
        })
        filteredContacto.value = []
      } else {
        if (!event.query.trim().length) {
          filteredContacto.value = [...ContactosAPI.value]
        } else {
          filteredContacto.value = ContactosAPI.value.filter((equipo) => {
            return equipo.Nombre.toLowerCase().includes(
              event.query.toLowerCase()
            )
          })
        }
      }
    }
    const searchSolicitante = (event) => {
      setTimeout(() => {
        if (!event.query.trim().length) {
          filteredSolicitante.value = [...Solicitantes.value]
        } else {
          filteredSolicitante.value = Solicitantes.value.filter(
            (solicitante) => {
              return solicitante.Nombre
                .toLowerCase()
                .startsWith(event.query.toLowerCase())
            }
          )
        }
      }, 250)
    }
    const SeleccionEquipo = (event) => {
      slider.Equipo = event.value.Equipo
      AuxiliaresAPI.value = event.value.Auxiliares
    }
    const SeleccionCliente = (event) => {
      slider.Cliente = event.value.Nombre
      ContactosAPI.value = event.value.Contactos
    }
    const SeleccionContacto = (event) => {
      slider.Contacto = event.value.Nombre
      slider.ContactoTelefono = event.value.Telefono
      slider.ContactoIniciales = event.value.Iniciales
    }
    const SeleccionSolicitante = (event, key) => {
      slider.Solicitantes[key].Nombre = event.value.Nombre
      slider.Solicitantes[key].CURP = event.value.CURP
      slider.Solicitantes[key].RFC = event.value.RFC
    }
    const SeleccionInmueble = (event) => {
      slider.InmuebleClave = event.value.Clave
      slider.InmuebleTipo = event.value.Tipo
      slider.InmuebleCalle = event.value.Calle
      slider.InmuebleNumExt = event.value.NumExt
      slider.InmuebleNumInt = event.value.NumInt
      slider.InmuebleCP = event.value.CP
      slider.InmuebleColonia = event.value.Colonia
      slider.InmuebleMunicipio = event.value.Municipio
      slider.InmuebleEstado = event.value.Estado
    }
    const SeleccionarEscrito = (event) => {
      event.preventDefault()
      if (slider.Equipo === null || slider.Equipo === '') {
        toast.add({
          severity: 'info',
          summary: 'Info Message',
          detail: 'Seleccione un Equipo',
          life: 3000
        })
        filteredAuxiliares.value = []
      } else {
        if (event.srcElement.value === '') {
          toast.add({
            severity: 'info',
            summary: 'Info Message',
            detail: 'Seleccione un Auxiliar Valido',
            life: 3000
          })
        } else {
          slider.Auxiliares.push({ name: event.srcElement.value })
        }
      }
    }

    /* Imprimir */
    const ImprimirHoja = () => {
      const d = new Printd()
      d.print(print.value, [cssText])
    }
    /* Crea la solicitud y ejecuta la funcion subirArchivos */
    const crearSolicitud = async (e) => {
      e.preventDefault()
      try {
        if (
          slider.TipoSolicitud !== null &&
          Object.prototype.hasOwnProperty.call(slider.TipoSolicitud, 'name')
        ) {
          slider.TipoSolicitud = slider.TipoSolicitud.name
        }
        if (
          slider.InmuebleTipo !== null &&
          Object.prototype.hasOwnProperty.call(slider.InmuebleTipo, 'name')
        ) {
          slider.InmuebleTipo = slider.InmuebleTipo.name
        }
        await todoSolicitudes.post(token.value, slider)
        tableComponent.value.tabulator.replaceData()
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Se creo Correctamente el Valor',
          life: 3000
        })
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response.data,
          life: 3000
        })
        console.error(error)
      }
    }
    const editarSolicitud = async (e) => {
      e.preventDefault()
      try {
        if (
          slider.TipoSolicitud !== null &&
          Object.prototype.hasOwnProperty.call(slider.TipoSolicitud, 'name')
        ) {
          slider.TipoSolicitud = slider.TipoSolicitud.name
        }
        if (
          slider.InmuebleTipo !== null &&
          Object.prototype.hasOwnProperty.call(slider.InmuebleTipo, 'name')
        ) {
          slider.InmuebleTipo = slider.InmuebleTipo.name
        }
        await todoSolicitudes.patch(token.value, slider, slider.id)
        tableComponent.value.tabulator.replaceData()
        toast.add({
          severity: 'success',
          summary: 'Success Message',
          detail: 'Se creo Correctamente el Valor',
          life: 3000
        })
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response,
          life: 3000
        })
        console.error(error)
      }
    }
    /* Elimina Valor */
    const eliminarSolicitud = async () => {
      try {
        await todoSolicitudes.delete(token.value, slider.id)
        toast.add({
          severity: 'success',
          summary: 'success Message',
          detail: 'Se elimino correctamente la solicitud',
          life: 3000
        })
      } catch (error) {
        toast.add({
          severity: 'error',
          summary: 'error Message',
          detail: error.response.data,
          life: 3000
        })
      }
    }
    /* Limpia los campos al abrir nueva Solicitud */
    const limpiarSlider = () => {
      nuevo.value = true
      slider.id = null
      slider.Folio = null
      slider.Anio = new Date().getFullYear()
      slider.Cliente = null
      slider.Contacto = null
      slider.ContactoTelefono = null
      slider.ContactoIniciales = null
      slider.InmuebleClave = null
      slider.InmuebleTipo = null
      slider.InmuebleCalle = null
      slider.InmuebleNumExt = null
      slider.InmuebleNumInt = null
      slider.InmuebleColonia = null
      slider.InmuebleMunicipio = null
      slider.InmuebleEstado = null
      slider.InmuebleCP = null
      slider.Expediente = null
      slider.Escritura = null
      slider.TipoSolicitud = null
      slider.Equipo = null
      slider.Auxiliares = []
      slider.Estatus = 'Pendiente'
      slider.Fecha = new Date(new Date().toString().split('GMT')[0] + ' UTC')
        .toISOString()
        .split('T')[0]
      slider.FechaEntrega = ''
      slider.Solicitantes = [
        {
          Nombre: null,
          CURP: null,
          RFC: null
        }
      ]
    }
    onMounted(() => {
      const infinito = {
        filters: [],
        page: 1,
        size: 10000,
        sorters: []
      }
      todoClientes.get(token.value, infinito).then((data) => {
        Clientes.value = data.data.data
        return Clientes.value
      })
      todoInmuebles.get(token.value, infinito).then((data) => {
        Inmuebles.value = data.data.data
        return Inmuebles.value
      })
      todoEquipos.get(token.value, infinito).then((data) => {
        Equipos.value = data.data.data
        return Equipos.value
      })
      todoSolicitantes.get(token.value, infinito).then((data) => {
        Solicitantes.value = data.data.data
        return Solicitantes.value
      })
    })
    return {
      /* Tabla */
      tableComponent,
      column,
      apiCall,
      /* END: Table */
      filteredCliente,
      searchCliente,
      filteredInmueble,
      searchInmueble,
      type,
      ...toRefs(slider),
      searchEquipo,
      filteredEquipo,
      filteredContacto,
      SeleccionEquipo,
      searchAuxiliares,
      filteredAuxiliares,
      filteredSolicitante,
      SeleccionarEscrito,
      SeleccionInmueble,
      searchContacto,
      SeleccionCliente,
      SeleccionContacto,
      crearSolicitud,
      typeSolicitud,
      limpiarSlider,
      eliminarSolicitud,
      editarSolicitud,
      nuevo,
      agregarNuevo,
      deleteContact,
      searchSolicitante,
      SeleccionSolicitante,
      print,
      ImprimirHoja,
      changeFecha
    }
  },
  components: {
    tabla
  }
})
</script>
