<template>
  <div>
    <div
      class="
        px-5
        sm:px-20
        mt-10
        pt-10
        border-t border-gray-200
        dark:border-dark-5
      "
    >
      <div class="font-medium text-base">Firmar</div>
      <div class="grid grid-cols-12 gap-4 gap-y-5 mt-5">
        <div class="intro-y col-span-12 sm:col-span-12">
          <label for="input-wizard-Firmar" class="form-label">Firmar</label>
          <input
            type="file"
            name=""
            id="input-wizard-Firmar"
            class="form-control"
            placeholder="Costo del Avalúo"
          />
        </div>
        <div
          class="
            intro-y
            col-span-12
            flex
            items-center
            justify-center
            sm:justify-end
            mt-5
          "
        >
          <button @click="SaveFirmar" class="btn btn-primary w-36 ml-2">
            Guardar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {
    const SaveFirmar = () => {
      console.log('Guardando....')
    }
    return {
      SaveFirmar
    }
  }
})
</script>

<style scoped>
</style>
